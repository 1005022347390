import { z } from 'zod'

export const schema = z.object({
  url: z.string().trim().min(1, 'Required!').url('Invalid URL!'),
  webhook_event_ids: z.string().array().min(1, 'Required!')
})

export type TField = z.infer<typeof schema>

export const DEFAULT_VALUES: TField = {
  url: '',
  webhook_event_ids: []
}
