import {
  getCurrentPasswordSettingValues,
  getDefaultPasswordSettingValues
} from '$services/api'
import { useRequest } from 'ahooks'

export const usePasswordSettingValues = () => {
  const {
    runAsync: fetchDefaultPasswordSettingValuesAsync,
    loading: fetchingDefaultPasswordSettingValues,
    data: defaultPasswordSettingValuesData
  } = useRequest(getDefaultPasswordSettingValues, { manual: true })

  const {
    runAsync: fetchCurrentPasswordSettingValuesAsync,
    loading: fetchingCurrentPasswordSettingValues,
    data: currentPasswordSettingValuesData
  } = useRequest(getCurrentPasswordSettingValues, { manual: true })

  return {
    fetchCurrentPasswordSettingValuesAsync,
    fetchingCurrentPasswordSettingValues,
    currentPasswordSettingValues: currentPasswordSettingValuesData?.data,

    fetchDefaultPasswordSettingValuesAsync,
    fetchingDefaultPasswordSettingValues,
    defaultPasswordSettingValues: defaultPasswordSettingValuesData?.data ?? []
  }
}
