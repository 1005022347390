import { FC, PropsWithChildren } from 'react'
import Sidebar from './Sidebar'
import { MainContainerStyle } from './styles.css'
import { INNER_HEADER_ID } from '$app/constants'
import { NavBarHeight } from '$layouts/PageLayout/styles.css'

const LayoutWithSidebar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section>
      <Sidebar />

      <article className={MainContainerStyle}>
        <header
          id={INNER_HEADER_ID}
          className="flex sticky"
          style={{ top: NavBarHeight, zIndex: 1 }}
        />

        <article className="flex w-full max-w-[1056px] mx-auto p-2 mb-[60px]">
          {children}
        </article>
      </article>
    </section>
  )
}

export default LayoutWithSidebar
