import { SERVICE_USER_TYPE } from '$services/api'
import { userTypeBadgeStyle } from './styles.css'

const UserTypeBadge = ({ userType }: { userType: SERVICE_USER_TYPE }) => {
  return (
    <span className={userTypeBadgeStyle} data-user-login-type={userType}>
      {userType} user
    </span>
  )
}

export default UserTypeBadge
