import { GENDER_OPTIONS } from '$app/constants'
import { cn, formatDateWithGMT, getDateOfBirth, getPhone } from '$app/utils'
import AppLogo from '$components/AppLogo'
import Breadcrumb from '$components/Breadcrumb/v2'
import { CopyButton } from '$components/CopyButon'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'
import LoginDevices from '$components/LoginDevices'
import Select from '$components/Select'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useGlobalAppUserService, useUserService } from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import LayoutWithBackground from '$layouts/LayoutWithBackground'
import { redirect, ROUTE_NAMES } from '$router/config'
import { card, description, title } from '$styles/common.css'
import { Avatar, Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount, useToggle } from 'ahooks'
import { Ban, Lock, Trash } from 'lucide-react'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  date_of_birth: z.string().trim().min(1, 'Requried!'),
  gender: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  }),
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  }),
  address: z.string().trim().min(1, 'Requried!')
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  date_of_birth: '',
  gender: { label: '', value: '' },
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: '',
  address: ''
}

const Info = ({ label, value }: { label: string; value: string }) => {
  return (
    <article className="flex flex-col gap-1">
      <p className="text-xs font-medium text-[--colors-text-disabled]">
        {label}
      </p>
      <p className="text-sm font-medium">{value ?? '-'}</p>
    </article>
  )
}

const GlobalAppUserDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appUserId } = params

  const isMounted = useIsMounted()

  const [isEditMode, { setLeft: setEditFalse, setRight: setEditTrue }] =
    useToggle()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const {
    fetchGlobalAppUserDetail,
    fetchingGlobalAppUserDetail,
    globalUser,
    updateGlobalUserAsync,
    updatingGlobalUser
  } = useGlobalAppUserService()

  const { updateUserStatusAsync, updatingUserStatus } = useUserService()

  const isUserActive = globalUser?.status === 'active'

  useMount(() => {
    if (!appUserId) return
    fetchGlobalAppUserDetail(appUserId)
  })

  const onEdit = useCallback(() => {
    const { phone_code, phone_no, gender } = globalUser ?? {}
    const modifiedGender = GENDER_OPTIONS.find(d => d.value === gender) ?? {
      label: '',
      value: ''
    }
    reset({
      ...globalUser,
      phone_no: phone_no ?? '',
      phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
      gender: modifiedGender
    })
    setEditTrue()
  }, [setEditTrue, globalUser, reset])

  const onCancel = useCallback(() => {
    setEditFalse()
    reset(DEFAULT_FORM_VALUES)
  }, [setEditFalse, reset])

  const onSubmit = useCallback(
    ({ gender, phone_code, ...rest }: TFormValues) => {
      if (!appUserId) return

      const payload = {
        ...rest,
        gender: gender.value,
        phone_code: phone_code.value
      }
      updateGlobalUserAsync(appUserId, payload).then(onCancel)
    },
    [appUserId, updateGlobalUserAsync, onCancel]
  )

  const onClickAppCard = useCallback((appId: number) => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [])

  const onAddApplication = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACH_APP, { params })
  }, [params])

  const onChangePassword = useCallback(() => {
    redirect(ROUTE_NAMES.UPDATE_PASSWORD, {
      params: { userId: appUserId },
      queryParams: { source: ROUTE_NAMES.GLOBAL_APP_USER_DETAIL }
    })
  }, [appUserId])

  const onTerminateUser = useCallback(() => {
    if (!appUserId) return
    updateUserStatusAsync({
      isAdmin: false,
      userId: appUserId,
      status: 'inactive'
    })
  }, [appUserId, updateUserStatusAsync])

  if (fetchingGlobalAppUserDetail && !isMounted) return <Loading />

  if (!globalUser) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_APP_USERS}
        data={[{ name: 'User Details' }]}
      />

      <article className="flex-1 flex flex-col">
        <LayoutWithBackground className="h-[216px] absolute" />

        <article className="flex flex-1 flex-col relative">
          <article className="grid lg:grid-cols-[370px_auto] gap-2">
            <article className="flex flex-col gap-2">
              <article className={cn('flex flex-col gap-4 px-5 py-4', card)}>
                <article className="flex flex-col gap-2">
                  <p className="font-semibold">Profile Name</p>

                  <p className="text-xs text-[--colors-neutral-50]">
                    Your name will be visible to your coworkers on Carro SSO.
                  </p>
                </article>

                <article className="flex gap-2">
                  <article>
                    <Avatar
                      status={
                        globalUser.status === 'active' ? 'online' : undefined
                      }
                      size={30}
                    />
                  </article>

                  <article className="flex flex-col gap-1">
                    <p className="text-sm font-medium text-[--colors-text-light]">
                      {globalUser.name}
                    </p>
                    <p className="text-xs text-[--colors-neutral-60]">
                      {globalUser.email}
                    </p>
                  </article>
                </article>

                <article className="flex items-center gap-2 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg p-4">
                  <article className="flex flex-col gap-1 flex-1">
                    <p className="text-xs text-[--colors-neutral-70] font-medium">
                      User ID
                    </p>
                    <p className="text-[10px] text-[--colors-neutral-70]">
                      #{globalUser.id}
                    </p>
                  </article>

                  <CopyButton value={globalUser.id} size={18} />
                </article>

                <p className="text-sm font-medium text-[--colors-text-disabled]">
                  Last Signed In {formatDateWithGMT(globalUser.last_login_at)}
                </p>
              </article>

              <article className={cn('flex flex-col gap-4 px-5 py-4', card)}>
                <article className="flex flex-col gap-2">
                  <p className="font-semibold">Setting</p>

                  <p className="text-xs text-[--colors-neutral-50]">
                    Your setting can be editable on Carro SSO.
                  </p>
                </article>

                <article className="flex flex-col gap-1 py-2">
                  <article>
                    <Button
                      onClick={onChangePassword}
                      styleVariants={{
                        kind: 'neutral',
                        type: 'text',
                        size: 'small'
                      }}
                    >
                      <Lock size={20} />
                      Change password
                    </Button>
                  </article>

                  <hr className="border border-[--colors-neutral-10]" />

                  <article>
                    <DeleteDoubleConfirmPopUp
                      item={{ name: globalUser.name, type: 'user' }}
                      onExecute={onTerminateUser}
                      loading={updatingUserStatus}
                      disabled={!isUserActive}
                    >
                      <Button
                        styleVariants={{
                          kind: 'danger',
                          type: 'text',
                          size: 'small'
                        }}
                        disabled={!isUserActive}
                      >
                        <Ban size={20} />
                        Terminate User
                      </Button>
                    </DeleteDoubleConfirmPopUp>
                  </article>

                  <hr className="border border-[--colors-neutral-10]" />

                  <article>
                    <Button
                      disabled
                      styleVariants={{
                        kind: 'danger',
                        type: 'text',
                        size: 'small'
                      }}
                    >
                      <Trash size={20} />
                      Delete user
                    </Button>
                  </article>
                </article>

                <article className="flex flex-col rounded bg-[--colors-area-low] px-4 py-3">
                  <p className="text-xs text-[--colors-text-disabled] font-medium">
                    Joined On
                  </p>
                  <p className="font-semibold text-sm">
                    {formatDateWithGMT(globalUser.last_login_at)}
                  </p>
                </article>
              </article>
            </article>

            <article className="flex flex-col gap-2">
              <article className={cn('flex flex-col gap-4 px-5 py-4', card)}>
                <form
                  className="grid md:grid-cols-2 gap-4"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <article className="col-span-2 flex items-center gap-2 justify-between">
                    <article className="flex flex-col gap-2">
                      <p className="font-semibold">Personal Details</p>

                      <p className="text-xs text-[--colors-neutral-50]">
                        Your personal details can be editable on Carro SSO.
                      </p>
                    </article>

                    <article className="flex items-center gap-2">
                      {!isEditMode && (
                        <Button
                          styleVariants={{ kind: 'neutral', type: 'outlined' }}
                          onClick={onEdit}
                        >
                          Edit
                        </Button>
                      )}

                      {isEditMode && (
                        <>
                          <Button
                            disabled={updatingGlobalUser}
                            styleVariants={{
                              kind: 'neutral',
                              type: 'outlined'
                            }}
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>

                          <Button
                            disabled={!isDirty || updatingGlobalUser}
                            type="submit"
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </article>
                  </article>
                  {isEditMode ? (
                    <BaseText
                      control={control}
                      name="name"
                      label="Name"
                      required
                    />
                  ) : (
                    <Info label="Name" value={globalUser.name} />
                  )}

                  {isEditMode ? (
                    <BaseText
                      control={control}
                      name="email"
                      label="Email"
                      required
                    />
                  ) : (
                    <Info label="Email" value={globalUser.email} />
                  )}

                  {isEditMode ? (
                    <article className="relative">
                      <BaseText
                        control={control}
                        name="phone_no"
                        label="Phone Number"
                        inputProps={{ className: 'pl-[100px]' }}
                      />

                      <article className="absolute bottom-0 left-0 w-[100px]">
                        <Controller
                          name="phone_code"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                value={field.value}
                                onChange={field.onChange}
                                options={phoneOptions}
                                type="sub"
                              />
                            )
                          }}
                        />
                      </article>
                    </article>
                  ) : (
                    <Info
                      label="Phone Number"
                      value={getPhone(
                        globalUser.phone_code,
                        globalUser.phone_no
                      )}
                    />
                  )}

                  {isEditMode ? (
                    <BaseText
                      control={control}
                      name="date_of_birth"
                      label="Date of Birth"
                      inputProps={{ type: 'date' }}
                      required
                    />
                  ) : (
                    <Info
                      label="Date of Birth"
                      value={getDateOfBirth(globalUser.date_of_birth)}
                    />
                  )}

                  {isEditMode ? (
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <article className="flex flex-col gap-y-1">
                            <label className="text-sm font-medium">
                              Gender
                            </label>
                            <Select
                              {...field}
                              options={GENDER_OPTIONS}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <p className="text-xs text-[--colors-danger-default]">
                                {error.message}
                              </p>
                            )}
                          </article>
                        )
                      }}
                    />
                  ) : (
                    <Info
                      label="Gender"
                      value={
                        GENDER_OPTIONS.find(d => d.value === globalUser.gender)
                          ?.label ?? '-'
                      }
                    />
                  )}

                  {isEditMode ? (
                    <Textarea
                      name="address"
                      control={control}
                      label="Address"
                      required
                    />
                  ) : (
                    <Info label="Address" value={globalUser.address} />
                  )}
                </form>
              </article>

              <article className={cn('flex flex-col gap-6 px-6 py-4', card)}>
                <article className="flex items-end gap-1">
                  <article className="flex-1 flex flex-col gap-1">
                    <p className="font-semibold">
                      {globalUser.applications.length
                        ? `ACCESS ${globalUser.applications.length} APPLICATIONS TO USE`
                        : 'NO APPLICATION TO USE'}
                    </p>
                    <p className="text-xs text-[--colors-neutral-50]">
                      You have access on these applications to manage on Carro
                      SSO.
                    </p>
                  </article>
                  <article>
                    <Button onClick={onAddApplication}>Add Application</Button>
                  </article>
                </article>

                <article className="grid md:grid-cols-2 gap-2">
                  {globalUser.applications.map((v, k) => {
                    return (
                      <button
                        onClick={() => onClickAppCard(v.id)}
                        key={k}
                        className="flex items-center gap-2 p-4 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg"
                      >
                        <AppLogo imgUrl={v.logo?.url} brand={v.brand} />
                        <p className="text-sm font-medium">{v.name}</p>
                      </button>
                    )
                  })}
                </article>

                {!globalUser.applications.length && (
                  <article className="flex-1 grid place-items-center">
                    <EmptyData type="application" />
                  </article>
                )}
              </article>

              <article className={cn('flex flex-col gap-6 px-5 py-4', card)}>
                <article className="flex flex-col gap-1">
                  <p className={title['type2']}>Devices Login</p>
                  <p className={description}>
                    These are the devices that user login.
                  </p>
                </article>

                <LoginDevices data={[]} />
              </article>
            </article>
          </article>
        </article>
      </article>
    </>
  )
}

export default GlobalAppUserDetail
