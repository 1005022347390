import { z } from 'zod'

const trimmedString = z.string().trim()

export const schema = z
  .object({
    password: z.string().trim().min(1, 'Required'),
    phone_no: z.string().trim().min(1, 'Required'),
    phone_code: z.object({
      value: trimmedString,
      label: trimmedString
    })
  })
  .superRefine((val, ctx) => {
    if (!val.phone_code.value) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required Phone Code',
        path: ['phone_no']
      })
    }
  })

export type TField = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TField = {
  password: '',
  phone_code: { label: '', value: '' },
  phone_no: ''
}
