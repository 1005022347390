import { Laptop2, Layers, Lock, LucideIcon, Shield, User2 } from 'lucide-react'
import {
  activeIndicator,
  menuContainer,
  menuIcon,
  menuItem,
  menuLabel
} from './styles.css'
import { ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { body } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { ready } from '$styles/common.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { USER_TYPE } from '$layouts/GlobalUserLayout/types'

const { colors } = themeVars

const MenuLink = ({
  Icon,
  label,
  routeName
}: {
  Icon: LucideIcon
  label: string
  routeName: ROUTE_NAMES
}) => {
  const {
    route: { params, name }
  } = useRouteSummary()

  const isActive = routeName === name

  return (
    <article className={menuContainer}>
      <Link
        to={routeName}
        options={{ params }}
        className={menuItem}
        data-active={trueOrUndefined(isActive)}
      >
        <Icon size={18} className={menuIcon} />

        <p className={menuLabel({ isActive })}>{label}</p>
      </Link>

      <span
        className={activeIndicator}
        data-ready={trueOrUndefined(isActive)}
      />
    </article>
  )
}

const ProfileSettingMenu = ({ userType }: { userType: USER_TYPE }) => {
  const isMounted = useIsMounted()

  return (
    <article className={ready} data-ready={trueOrUndefined(isMounted)}>
      <MenuLink
        Icon={Layers}
        label="Access Applications"
        routeName={
          userType === USER_TYPE.SERVICE_USER
            ? ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS
            : ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ACCESS_APPS
        }
      />

      <MenuLink
        Icon={User2}
        label="User Details"
        routeName={
          userType === USER_TYPE.SERVICE_USER
            ? ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL
            : ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL
        }
      />

      <article className="flex flex-col gap-2 py-2">
        <p
          className={body.five}
          style={{ color: colors.neutral[60], padding: '0 16px' }}
        >
          SECURITY
        </p>

        {userType === USER_TYPE.SERVICE_USER && (
          <MenuLink
            Icon={Laptop2}
            label="Devices Login"
            routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DEVICES_LOGIN}
          />
        )}

        <MenuLink
          Icon={Lock}
          label="Password Setting"
          routeName={
            userType === USER_TYPE.SERVICE_USER
              ? ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING
              : ROUTE_NAMES.GLOBAL_DASHBOARD_USER_PASSWORD_SETTING
          }
        />

        {userType === USER_TYPE.SERVICE_USER && (
          <MenuLink
            Icon={Shield}
            label="Multi-Factor Authentication"
            routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_MFA}
          />
        )}
      </article>
    </article>
  )
}

export default ProfileSettingMenu
