import { APP_TOKEN_KEY } from '$app/constants'
import { redirect, ROUTE_NAMES } from '$app/router/config'
import {
  getProfile,
  getTimezones,
  getWebhookEvents,
  updateAxiosHeaderToken
} from '$app/services/api'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from '$app/utils'
import { useRequest } from 'ahooks'
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { AuthContext } from './context'
import { useAxiosInterceptors } from '$hooks/actions'
import {
  useAppScopeService,
  useCountryService,
  usePasswordPolicyService
} from '$hooks/services'
import Loading from '$components/Loading'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { useWebhookEventStore } from '$hooks/stores/useWebhookEventStore'

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState(getLocalStorage<string>(APP_TOKEN_KEY))

  const isMounted = useIsMounted()

  const { data: profileData, run: fetchProfile } = useRequest(getProfile, {
    manual: true
  })

  const { data: profile } = profileData ?? {}

  const { data: timezoneData, run: fetchTimezones } = useRequest(getTimezones, {
    manual: true
  })

  const updateWebhookEvents = useWebhookEventStore(
    state => state.updateWebhookEvents
  )

  const { run: fetchWebhookEvents } = useRequest(getWebhookEvents, {
    manual: true,
    onSuccess: ({ data }) => {
      updateWebhookEvents(data)
    }
  })

  const { fetchCountries } = useCountryService()

  const { fetchAppScope } = useAppScopeService()

  const { fetchPasswordPolicySetting } = usePasswordPolicyService()

  const updateToken = useCallback((value?: string) => {
    setToken(value)

    if (value) {
      setLocalStorage(APP_TOKEN_KEY, value)
      return
    }

    removeLocalStorage(APP_TOKEN_KEY)
  }, [])

  useAxiosInterceptors(() => updateToken(undefined))

  useEffect(() => {
    updateAxiosHeaderToken(token)

    if (!token) {
      redirect(ROUTE_NAMES.LOGIN, { replace: true })
      return
    }

    fetchProfile()

    fetchCountries()

    fetchAppScope()

    fetchPasswordPolicySetting()

    fetchTimezones()

    fetchWebhookEvents()
  }, [
    token,
    fetchProfile,
    fetchCountries,
    fetchAppScope,
    fetchPasswordPolicySetting,
    fetchTimezones,
    fetchWebhookEvents
  ])

  if (!isMounted) return <Loading />

  return (
    <AuthContext.Provider
      value={{
        user: profile,
        token,
        updateToken,
        timezones: timezoneData?.data ?? []
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
