import { z } from 'zod'

export const schema = z.object({
  password: z.string().trim().min(1, 'Required'),
  email: z.string().trim().min(1, 'Required!').email()
})

export type TField = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TField = {
  password: '',
  email: ''
}
