import { SERVICE_USER_TYPE } from '$services/api'
import { z } from 'zod'

export const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  date_of_birth: z.string().trim(), //.min(1, 'Requried!'),
  gender: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  }),
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  }),
  address: z.string().trim(),
  status: z.string().trim(),
  roles: z.number().array().min(1, 'Please assign one role at least!'),
  user_type: z.string().trim()
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  date_of_birth: '',
  gender: { label: '', value: '' },
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: '',
  address: '',
  status: 'active',
  roles: [],
  user_type: SERVICE_USER_TYPE.INTERNAL
}
