import { INNER_HEADER_ID } from '$app/constants'
import { useMount } from 'ahooks'
import { FC, PropsWithChildren, useState } from 'react'
import { createPortal } from 'react-dom'

const Header: FC<PropsWithChildren> = ({ children }) => {
  const [targetContainer, setTargetContainer] = useState<HTMLElement | null>(
    null
  )

  useMount(() => {
    setTargetContainer(document.getElementById(INNER_HEADER_ID))
  })

  if (!targetContainer) return null

  return createPortal(children, targetContainer)
}

export default Header
