import { header, headerItem, listItem } from './styles.css'
import { formatNumber } from '$app/utils'
import EmptyData from '$components/EmptyData'
import { LucideIcon } from 'lucide-react'

const VisitorList = ({
  title,
  data
}: {
  title: string
  data: { display_name: string; count: number; Icon?: LucideIcon }[]
}) => {
  return (
    <article className="flex flex-col gap-4 flex-1">
      <header className={header}>
        <p className={headerItem}>{title}</p>
        <p className={headerItem}>Visitors</p>
      </header>

      {!data.length && <EmptyData type="visitors" />}

      {data.map(({ Icon, ...v }) => {
        return (
          <article key={v.display_name} className="flex items-center gap-2">
            {Icon && <Icon size={20} />}
            <p className={listItem} style={{ flex: 1 }}>
              {v.display_name}
            </p>
            <p className={listItem}>{formatNumber(v.count)}</p>
          </article>
        )
      })}
    </article>
  )
}

export default VisitorList
