import EnterTransitionBlock from '$components/EnterTransitionBlock'
import PopupModal from '$components/PopupModal'
import { useGlobalServiceUserService, useUserService } from '$hooks/services'
import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import { useUserId } from '$layouts/GlobalUserLayout/hooks/useUserId'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { USER_TYPE } from '$layouts/GlobalUserLayout/types'
import { description } from '$styles/common.css'
import { Button, Checkbox } from '@genie-fintech/ui/components'
import { title } from '@genie-fintech/ui/style/typography'
import { useBoolean, useMount } from 'ahooks'
import { Ban, ShieldCheck, ShieldClose } from 'lucide-react'
import { useCallback } from 'react'
import { shield } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'

const GlobalServiceUserMFASetting = ({ userType }: { userType: USER_TYPE }) => {
  const [open, { setTrue, setFalse }] = useBoolean()

  const { userId } = useUserId(userType)

  const {
    fetchServiceUserMFASetting,
    fetchingServiceUserMFASetting,
    user_mfa,
    enforceMFAAsync,
    enforcingMFA
  } = useGlobalServiceUserService()

  const { updateUserStatusAsync, updatingUserStatus } = useUserService()

  const { name, status } = useUserInfoStore(state => state.userInfo) ?? {}

  const { mfa_enforced = false } = user_mfa ?? {}
  useMount(() => {
    if (!userId) return
    fetchServiceUserMFASetting({ userId })
  })

  const onMFACheckboxChange = useCallback(() => {
    setTrue()
  }, [setTrue])

  const onConfirm = useCallback(() => {
    if (!userId) return
    enforceMFAAsync(userId, { enforce_mfa: !mfa_enforced })
      .then(() => {
        fetchServiceUserMFASetting({ userId })
      })
      .then(setFalse)
  }, [
    enforceMFAAsync,
    userId,
    mfa_enforced,
    fetchServiceUserMFASetting,
    setFalse
  ])

  const onTerminateUser = useCallback(() => {
    if (!userId) return
    updateUserStatusAsync({
      isAdmin: false,
      userId: userId,
      status: 'inactive'
    })
  }, [userId, updateUserStatusAsync])

  const isUserActive = status === 'active'

  if (!user_mfa) return <Loading />

  return (
    <>
      <PopupModal open={open} onConfirm={onConfirm} onClose={setFalse} />

      <EnterTransitionBlock className={innerMainContainerStyle}>
        <MainHeader
          title="MULTI-FACTOR AUTHENTICATION"
          desc="It provides more than one form of authentication to access an account or application."
        />

        <article className={mainSubContainerStyle}>
          <article className="flex items-center gap-8 flex-wrap">
            <article className="flex flex-col gap-1 flex-1">
              <p className={title.two}>
                TURN ON/OFF MULTI-FACTOR AUTHENTICATION
              </p>
              <p className={description}>
                Prevent hackers from accessing your account with an additional
                layer of security.
              </p>
            </article>

            <article className="flex flex-nowrap items-center justify-end gap-4">
              <Checkbox
                label="Turn on multi-factor authentication"
                boxProps={{
                  checked: !!mfa_enforced,
                  onCheckedChange: onMFACheckboxChange
                }}
                disabled={enforcingMFA || fetchingServiceUserMFASetting}
              />

              <article
                className={shield}
                data-active={trueOrUndefined(mfa_enforced)}
              >
                {mfa_enforced ? (
                  <ShieldCheck size={24} />
                ) : (
                  <ShieldClose size={24} />
                )}
              </article>
            </article>
          </article>
        </article>

        <DangerZone type="user">
          <DeleteDoubleConfirmPopUp
            item={{ name: name ?? '', type: 'user' }}
            onExecute={onTerminateUser}
            loading={updatingUserStatus}
            disabled={!isUserActive}
          >
            <Button
              styleVariants={{
                kind: 'danger',
                type: 'text',
                size: 'small'
              }}
              disabled={!isUserActive}
            >
              <Ban size={20} />
              Terminate User
            </Button>
          </DeleteDoubleConfirmPopUp>
        </DangerZone>
      </EnterTransitionBlock>
    </>
  )
}

export default GlobalServiceUserMFASetting
