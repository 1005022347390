import { formatDateWithGMT } from '$app/utils'
import { themeVars } from '@genie-fintech/ui/style/theme'
import {
  ChevronRight,
  Clock,
  Inbox,
  LucideIcon,
  MapPin,
  Monitor,
  Smartphone,
  Tablet,
  User
} from 'lucide-react'
import { useState } from 'react'

const { colors } = themeVars

const getDeviceIcon = (device: string): LucideIcon => {
  if (device.includes('desktop')) return Monitor
  if (device.includes('phone')) return Smartphone
  return Tablet
}

const EmptyData = () => {
  return (
    <article className="flex-1 flex flex-col min-h-[100px] p-3 gap-3 items-center justify-center">
      <Inbox size={48} style={{ color: colors.neutral[30] }} />
      <p className="text-xs font-medium" style={{ color: colors.neutral[40] }}>
        There is no devices to show
      </p>
    </article>
  )
}

interface IProps {
  data: {
    browser: string
    created_at: string
    device: string
    id: string
    location: string
    platform: string
    user_email: string
  }[]
}

const LoginDevices = ({ data }: IProps) => {
  const [deviceId, setDeviceId] = useState('')

  return (
    <article className="flex-1 flex flex-col gap-2">
      {!data.length && <EmptyData />}

      {data.map((v, k) => {
        const Icon = getDeviceIcon(v.device)
        return (
          <article
            key={k}
            className="overflow-hidden flex flex-col gap-2 w-full"
          >
            <header className="flex flex-1">
              <button
                className="flex flex-1 gap-2"
                onClick={() => setDeviceId(prev => (prev === v.id ? '' : v.id))}
              >
                <Icon size={20} className="text-[--colors-text-disabled]" />

                <article className="flex-1 flex flex-col gap-1 items-start justify-start">
                  <p className="text-xs font-semibold text-[--colors-text-light] capitalize text-left">
                    {`${v.device} - ${v.browser}`}
                  </p>
                  <p className="text-xs font-medium text-[--colors-text-disabled]">
                    {v.platform}
                  </p>
                </article>

                <article className="flex h-full items-center">
                  <ChevronRight
                    data-active={deviceId === v.id}
                    className="text-[--colors-text-disabled] data-[active=true]:rotate-90 duration-200"
                    size={16}
                  />
                </article>
              </button>
            </header>
            <main
              data-active={deviceId === v.id}
              className="flex h-0 data-[active=true]:h-[75px] transition-all duration-200"
            >
              <article className="flex flex-col gap-1 w-full">
                <article className="flex items-center gap-1">
                  <User size={16} className="text-[--colors-text-disabled]" />
                  <p className="flex-1 text-xs text-[--colors-neutral-50]">
                    Email
                  </p>
                  <p className="text-xs text-[--colors-neutral-50]">
                    {v.user_email}
                  </p>
                </article>

                <article className="flex items-center gap-1">
                  <Clock size={16} className="text-[--colors-text-disabled]" />
                  <p className="flex-1 text-xs text-[--colors-neutral-50]">
                    Login Activity
                  </p>
                  <p className="text-xs text-[--colors-neutral-50]">
                    {formatDateWithGMT(v.created_at)}
                  </p>
                </article>

                <article className="flex items-center gap-1">
                  <MapPin size={16} className="text-[--colors-text-disabled]" />
                  <p className="flex-1 text-xs text-[--colors-neutral-50]">
                    Location
                  </p>
                  <p className="text-xs text-[--colors-neutral-50]">
                    {v.location}
                  </p>
                </article>
              </article>
            </main>
          </article>
        )
      })}
    </article>
  )
}

export default LoginDevices
