import { PERMISSIONS } from '$app/constants'
import { ROUTE_NAMES } from '$router/config'
import {
  FolderOpen,
  LucideIcon,
  Settings,
  UserCog2,
  Users2
} from 'lucide-react'

export const MainSidebarMenu: {
  name: string
  to: ROUTE_NAMES
  Icon: LucideIcon
  subPath: ROUTE_NAMES[]
  permissions: PERMISSIONS[]
}[] = [
  {
    name: 'Applications',
    to: ROUTE_NAMES.APPS,
    Icon: FolderOpen,
    subPath: [
      ROUTE_NAMES.APP_CREATE,
      ROUTE_NAMES.APP_HOME,
      ROUTE_NAMES.APP_BASIC_SETTING,
      ROUTE_NAMES.APP_ADVANCED_SETTING,
      ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
      ROUTE_NAMES.APP_GROUPS,
      ROUTE_NAMES.APP_GROUP_DETAIL,
      ROUTE_NAMES.APP_GROUP_CREATE,
      ROUTE_NAMES.APP_GROUP_EDIT,
      ROUTE_NAMES.APP_ROLES,
      ROUTE_NAMES.APP_ROLE_DETAIL,
      ROUTE_NAMES.APP_ROLE_CREATE,
      ROUTE_NAMES.APP_ROLE_EDIT,
      ROUTE_NAMES.APP_USERS,
      ROUTE_NAMES.APP_USER_DETAIL,
      ROUTE_NAMES.APP_USER_CREATE,
      ROUTE_NAMES.APP_USER_ASSIGN_ROLES,
      ROUTE_NAMES.APP_PERMISSIONS,
      ROUTE_NAMES.APP_PERMISSION_DETAIL,
      ROUTE_NAMES.APP_PERMISSION_CREATE,
      ROUTE_NAMES.APP_PERMISSION_EDIT,
      ROUTE_NAMES.APP_ROLES_CSV_IMPORT,
      ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT,
      ROUTE_NAMES.APP_USERS_CSV_IMPORT
    ],
    permissions: [PERMISSIONS.LIST_APPLICATION]
  },
  {
    name: 'Application Users',
    to: ROUTE_NAMES.GLOBAL_APP_USERS,
    Icon: Users2,
    subPath: [
      ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS,
      ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACH_APP,
      ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL,
      ROUTE_NAMES.GLOBAL_SERVICE_USER_DEVICES_LOGIN,
      ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING,
      ROUTE_NAMES.GLOBAL_SERVICE_USER_MFA
    ],
    permissions: []
  },
  {
    name: 'Dashboard Users',
    to: ROUTE_NAMES.GLOBAL_DASHBOARD_USERS,
    Icon: UserCog2,
    subPath: [
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE,
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL,
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP,
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_PASSWORD_SETTING
    ],
    permissions: []
  },
  {
    name: 'Global Setting',
    to: ROUTE_NAMES.GLOBAL_SETTING,
    Icon: Settings,
    subPath: [
      ROUTE_NAMES.COUNTRIES,
      ROUTE_NAMES.COUNTRY_CREATE,
      ROUTE_NAMES.COUNTRY_EDIT,
      ROUTE_NAMES.PASSWORD_POLICY
    ],
    permissions: []
  }
]
