import { cn, formatNumber } from '$app/utils'
import TodayBadge from '$blocks/AppHome/components/TodayBadge'
import TodayCard from '$blocks/AppHome/components/TodayCard'
import { TAppHome } from '$services/api'
import {
  card,
  defaultTitleFontWeight,
  description,
  title
} from '$styles/common.css'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getDailyActiveUserOption } from './utils'

interface IProps {
  active_users: TAppHome['active_users']
  today_active_user_count: TAppHome['today_active_user_count']
}

const DailyActiveUserChart = ({
  today_active_user_count,
  active_users
}: IProps) => {
  return (
    <article className={cn('flex-1 flex flex-col p-6 gap-6', card)}>
      <article className="flex-1 flex gap-4">
        <article className="flex-1 flex flex-col gap-1">
          <p className={title['type3']}>APPLICATION DAILY ACTIVE USERS</p>

          <article className="flex items-center gap-2">
            <p className={defaultTitleFontWeight} style={{ fontSize: 28 }}>
              {formatNumber(today_active_user_count)}
            </p>
            <TodayBadge />
          </article>

          <p className={description}>
            Analyze daily active users to track application engagement and usage
            trends.
          </p>
        </article>

        <TodayCard />
      </article>

      <article className="flex flex-col gap-4">
        <div style={{ width: '100%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getDailyActiveUserOption(active_users)}
          />
        </div>
      </article>
    </article>
  )
}

export default DailyActiveUserChart
