import { Button, Spinner } from '@genie-fintech/ui/components'
import {
  container,
  flexColumn,
  header,
  line,
  modalFooter,
  title as titleStyle,
  value
} from './styles.css'
import { description } from '$styles/common.css'
import * as Dialog from '@radix-ui/react-dialog'
import { useRequest, useToggle } from 'ahooks'
import { useCallback } from 'react'
import {
  BaseText as BaseHookFieldText,
  Password
} from '@genie-fintech/ui/components/hook-fields'
import { useForm } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TField } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { putServiceUserEmail } from '$services/api'
import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'

const Header = ({ title, desc }: { title: string; desc: string }) => (
  <header className={header}>
    <h3 className={titleStyle}>{title}</h3>
    <p className={description}>{desc}</p>
  </header>
)

const ChangeEmailSetting = () => {
  const [modalOpen, { toggle }] = useToggle()

  const { control, handleSubmit, reset } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const { userInfo, updateUserInfo } = useUserInfoStore(
    useShallow(state => ({
      userInfo: state.userInfo,
      updateUserInfo: state.updateUserInfo
    }))
  )

  const {
    runAsync: updateServiceUserEmail,
    loading: updatingServiceUserEmail
  } = useRequest(putServiceUserEmail, {
    manual: true,
    onSuccess: (_, [{ email }]) => {
      if (userInfo) updateUserInfo({ ...userInfo, email })
      toggle()
      reset(DEFAULT_FORM_VALUES)
      toast.success('Email has been updated!')
    }
  })

  const onCancel = useCallback(() => {
    reset(DEFAULT_FORM_VALUES)
    toggle()
  }, [reset, toggle])

  const onContinue = useCallback(
    (values: TField) => {
      updateServiceUserEmail({ userId: userInfo?.id ?? '', ...values })
    },
    [updateServiceUserEmail, userInfo?.id]
  )

  return (
    <>
      <article className={container}>
        <Header
          title="ADD/EDIT EMAIL ADDRESS"
          desc="You can update your email with anytime."
        />

        <p className={value}>{userInfo?.email}</p>

        <Button
          onClick={toggle}
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
        >
          Change
        </Button>
      </article>

      <Dialog.Root open={modalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content
            className="DialogContent flex flex-col max-w-[500px]"
            aria-describedby=""
          >
            <Dialog.Title className="hidden" />

            <form onSubmit={handleSubmit(onContinue)}>
              <main className={flexColumn} style={{ padding: 32, gap: 24 }}>
                <Header
                  title={userInfo?.email ? 'ENTER NEW EMAIL' : 'ADD EMAIL'}
                  desc={
                    userInfo?.email
                      ? 'Enter new email to continue the changes.'
                      : 'Enter email to continue the process.'
                  }
                />

                <article className={flexColumn} style={{ gap: 32 }}>
                  <Password
                    name="password"
                    control={control}
                    label="Password"
                    description="Enter your login password to confirm the changes."
                    required
                  />

                  <hr className={line} />

                  <article className={flexColumn} style={{ gap: 16 }}>
                    {userInfo?.email && (
                      <BaseText
                        label="Current Email"
                        inputProps={{ defaultValue: userInfo?.email ?? '' }}
                        disabled
                        required
                      />
                    )}

                    <BaseHookFieldText
                      name="email"
                      control={control}
                      label="New Email"
                      required
                    />
                  </article>
                </article>
              </main>

              <footer className={modalFooter}>
                <Button
                  type="button"
                  styleVariants={{
                    kind: 'neutral',
                    type: 'outlined',
                    size: 'small'
                  }}
                  onClick={onCancel}
                  disabled={updatingServiceUserEmail}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  styleVariants={{ size: 'small' }}
                  disabled={updatingServiceUserEmail}
                >
                  {updatingServiceUserEmail && <Spinner />}
                  Continue
                </Button>
              </footer>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default ChangeEmailSetting
