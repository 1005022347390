import MapView from '$blocks/AppHome/components/MapView'
import VisitorCard from '$blocks/AppHome/components/VisitorCard'
import VisitorList from '$blocks/AppHome/components/VisitorList'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppHomeService } from '$hooks/services'
import { useMount } from 'ahooks'
import { Loader } from 'lucide-react'
import { useState } from 'react'

const tabs = [
  { label: 'Map', value: 'map' },
  { label: 'Countries', value: 'country' }
]

const Locations = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const [activeTab, setActiveTab] = useState(tabs[0].value)

  const {
    fetchVisitorsByLocation,
    fetchingVisitorsByLocation,
    visitors_by_location
  } = useAppHomeService()

  useMount(() => {
    if (!appId) return
    fetchVisitorsByLocation({ application_id: appId })
  })

  return (
    <VisitorCard
      header="Locations"
      tabs={tabs}
      onChangeTab={setActiveTab}
      currentTab={activeTab}
    >
      {fetchingVisitorsByLocation && <Loader />}

      {activeTab === tabs[0].value && <MapView data={visitors_by_location} />}

      {activeTab === tabs[1].value && (
        <VisitorList title="Country" data={visitors_by_location} />
      )}
    </VisitorCard>
  )
}

export default Locations
