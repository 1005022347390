import { description } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { capitalize } from 'lodash-es'
import { Plus } from 'lucide-react'
import { buttonContainerStyle } from './styles.css'

const { colors } = themeVars

interface IEmptyDataProps {
  type: string
  onClick?: VoidFunction
}

const EmptyData = ({ type, onClick }: IEmptyDataProps) => {
  return (
    <article className="flex flex-col justify-center items-center gap-4 flex-1">
      {onClick && (
        <button onClick={onClick} className={buttonContainerStyle}>
          <Plus size={32} />
          <span className="font-medium text-sm">
            Add New {capitalize(type)}
          </span>
        </button>
      )}

      <article className="flex flex-col items-center gap-2">
        <p
          className="text-xl font-semibold"
          style={{ color: colors.neutral[70] }}
        >
          You don't have any {type} yet.
        </p>

        <p className={description}>No Items have been added to this section</p>
      </article>
    </article>
  )
}

export default EmptyData
