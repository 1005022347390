import { Button, Spinner } from '@genie-fintech/ui/components'
import {
  container,
  flexColumn,
  header,
  line,
  modalFooter,
  title as titleStyle,
  value
} from './styles.css'
import { description } from '$styles/common.css'
import * as Dialog from '@radix-ui/react-dialog'
import { useRequest, useToggle } from 'ahooks'
import { useCallback } from 'react'
import {
  BaseText as BaseHookFieldText,
  Password
} from '@genie-fintech/ui/components/hook-fields'
import { Controller, useForm } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TField } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { putServiceUserPhone } from '$services/api'
import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'
import Select from '$components/Select'
import { useCountryStore } from '$hooks/stores'
import { getPhone } from '$app/utils'
import { Icon } from '@genie-fintech/ui/icons'
import { BaseText } from '@genie-fintech/ui/components/fields'

const Header = ({ title, desc }: { title: string; desc: string }) => (
  <header className={header}>
    <h3 className={titleStyle}>{title}</h3>
    <p className={description}>{desc}</p>
  </header>
)

const ChangePhoneSetting = () => {
  const [modalOpen, { toggle }] = useToggle()

  const { control, handleSubmit, reset } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const { userInfo, updateUserInfo } = useUserInfoStore(
    useShallow(state => ({
      userInfo: state.userInfo,
      updateUserInfo: state.updateUserInfo
    }))
  )

  const {
    runAsync: updateServiceUserPhone,
    loading: updatingServiceUserPhone
  } = useRequest(putServiceUserPhone, {
    manual: true,
    onSuccess: (_, [{ phone_code, phone_no }]) => {
      if (userInfo) updateUserInfo({ ...userInfo, phone_code, phone_no })
      toggle()
      reset(DEFAULT_FORM_VALUES)
      toast.success('Phone has been updated!')
    }
  })

  const onCancel = useCallback(() => {
    reset(DEFAULT_FORM_VALUES)
    toggle()
  }, [reset, toggle])

  const onContinue = useCallback(
    ({ phone_code, ...payload }: TField) => {
      updateServiceUserPhone({
        userId: userInfo?.id ?? '',
        phone_code: phone_code.value,
        ...payload
      })
    },
    [updateServiceUserPhone, userInfo?.id]
  )

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const hasPhone = userInfo?.phone_code || userInfo?.phone_no

  return (
    <>
      <article className={container}>
        <Header
          title="ADD/EDIT PHONE NUMBER"
          desc="You can update your phone number with anytime."
        />

        <p className={value}>
          {hasPhone && getPhone(userInfo?.phone_code, userInfo?.phone_no)}
        </p>

        <Button
          onClick={toggle}
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
        >
          {!hasPhone && <Icon namespace="Add" />}
          {hasPhone ? 'Change' : 'Add Phone Number'}
        </Button>
      </article>

      <Dialog.Root open={modalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content
            className="DialogContent flex flex-col max-w-[500px]"
            aria-describedby=""
          >
            <Dialog.Title className="hidden" />

            <form onSubmit={handleSubmit(onContinue)}>
              <main className={flexColumn} style={{ padding: 32, gap: 24 }}>
                <Header
                  title={
                    hasPhone ? 'ENTER NEW PHONE NUMBER' : 'ADD PHONE NUMBER'
                  }
                  desc={
                    hasPhone
                      ? 'Enter new phone number to continue the changes.'
                      : 'Enter phone number to continue the process.'
                  }
                />

                <article className={flexColumn} style={{ gap: 32 }}>
                  <Password
                    name="password"
                    control={control}
                    label="Password"
                    description="Enter your login password to confirm the changes."
                    required
                  />

                  <hr className={line} />

                  <article className={flexColumn} style={{ gap: 16 }}>
                    {hasPhone && (
                      <article className="relative">
                        <BaseText
                          label="Current Phone Number"
                          inputProps={{
                            className: 'pl-[100px]',
                            defaultValue: userInfo?.phone_no ?? '',
                            readOnly: true
                          }}
                          disabled
                        />

                        <article className="absolute bottom-0 left-0 w-[100px]">
                          <Select
                            value={{
                              label: userInfo?.phone_code ?? '',
                              value: userInfo?.phone_code ?? ''
                            }}
                            options={phoneOptions}
                            disabled
                            type="sub"
                          />
                        </article>
                      </article>
                    )}

                    <article className="relative">
                      <BaseHookFieldText
                        name="phone_no"
                        control={control}
                        label={hasPhone ? 'New Phone Number' : 'Phone Number'}
                        inputProps={{ className: 'pl-[100px]' }}
                        required
                      />

                      <article className="absolute top-[27px] left-0 w-[100px]">
                        <Controller
                          name="phone_code"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                value={field.value}
                                onChange={field.onChange}
                                options={phoneOptions}
                                type="sub"
                                menuPlacement="top"
                              />
                            )
                          }}
                        />
                      </article>
                    </article>
                  </article>
                </article>
              </main>

              <footer className={modalFooter}>
                <Button
                  type="button"
                  styleVariants={{
                    kind: 'neutral',
                    type: 'outlined',
                    size: 'small'
                  }}
                  onClick={onCancel}
                  disabled={updatingServiceUserPhone}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  styleVariants={{ size: 'small' }}
                  disabled={updatingServiceUserPhone}
                >
                  {updatingServiceUserPhone && <Spinner />}
                  Continue
                </Button>
              </footer>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default ChangePhoneSetting
