import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useMemo } from 'react'
import { USER_TYPE } from '../types'

export const useUserId = (usertype: USER_TYPE) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { serviceUserId, dashboardUserId } = params

  const userId = useMemo(() => {
    if (usertype === USER_TYPE.SERVICE_USER) return serviceUserId
    if (usertype === USER_TYPE.DASHBOARD_USER) return dashboardUserId

    return ''
  }, [usertype, serviceUserId, dashboardUserId])

  return {
    userId
  }
}
