import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { card, container } from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { description, statusBadgeStyle } from '$styles/common.css'
import ActiveInactiveStatusBadge from '$components/ActiveInactiveStatusBadge'
import CopyButton from '$components/CopyButon/v2'
import { Avatar } from '@genie-fintech/ui/components'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { formatDateWithGMT } from '$app/utils'

const { colors } = themeVars

const neutralColor70 = colors.neutral[70]

const ProfileInfo = () => {
  const isMounted = useIsMounted()

  const userInfo = useUserInfoStore(state => state.userInfo)

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article className="flex flex-1 items-center gap-2">
        <Avatar
          status={userInfo?.status === 'active' ? 'online' : undefined}
          size={32}
        />

        <article>
          <article className="inline-flex items-center gap-1.5">
            <p className={title.six} style={{ color: colors.text.light }}>
              {userInfo?.name}
            </p>

            <ActiveInactiveStatusBadge status={userInfo?.status} />
          </article>

          <p className={description}>{userInfo?.email}</p>
        </article>
      </article>

      <article className="flex flex-col gap-2">
        <article className={card}>
          <article className="flex-1 flex flex-col gap-1 truncate min-w-0">
            <p className={footnote.two} style={{ color: neutralColor70 }}>
              User ID
            </p>
            <p style={{ color: neutralColor70, fontSize: 10 }}>
              {`#${userInfo?.id ?? ''}`}
            </p>
          </article>

          <CopyButton value={userInfo?.id} size={16} color="text.neutral" />
        </article>

        <article
          data-active
          className={statusBadgeStyle}
          style={{ padding: '4px 6px' }}
        >
          Last Signed In {formatDateWithGMT(userInfo?.last_login_at)}
        </article>
      </article>
    </article>
  )
}

export default ProfileInfo
