import { description } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { title } from '@genie-fintech/ui/style/typography'
import { container } from './styles.css'
import { Radio } from '@genie-fintech/ui/components'
import { SERVICE_USER_TYPE } from '$services/api'
import { FC, PropsWithChildren, useCallback } from 'react'
import UserTypeBadge from '$components/UserTypeBadge'

const { colors } = themeVars

interface IProps {
  value: SERVICE_USER_TYPE
  onChange?: (value: SERVICE_USER_TYPE) => void
  showBadge?: boolean
}

const LoginUserType: FC<PropsWithChildren<IProps>> = ({
  value,
  onChange,
  showBadge,
  children
}) => {
  const onValueChange = useCallback(
    (value: string) => {
      onChange?.(value as SERVICE_USER_TYPE)
    },
    [onChange]
  )

  return (
    <article className={container}>
      <article className="flex items-center gap-6">
        <article className="flex-1 flex flex-col gap-1">
          <p className={title.two} style={{ color: colors.text.neutral }}>
            LOGIN USER TYPE
          </p>
          <p className={description}>
            The login behavior may vary depending on the selected login user
            type.
          </p>
        </article>

        {showBadge && <UserTypeBadge userType={value} />}
      </article>

      {onChange && (
        <Radio.Group
          asChild
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
          value={value}
          onValueChange={onValueChange}
        >
          <main>
            <Radio.Item
              label="Internal login user"
              value={SERVICE_USER_TYPE.INTERNAL}
              styleVariants={{ bordered: true, padded: true, fullWidth: true }}
            />
            <Radio.Item
              label="External login user"
              value={SERVICE_USER_TYPE.EXTERNAL}
              styleVariants={{ bordered: true, padded: true, fullWidth: true }}
            />
          </main>
        </Radio.Group>
      )}

      {children}
    </article>
  )
}

export default LoginUserType
