import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppHomeService } from '$hooks/services'
import { useEffect } from 'react'
import PublishAppSetting from '$blocks/AppHome/sections/PublishAppSetting'
import AppSummaryCard from '$blocks/AppHome/sections/AppSummaryCard'
import { ItemType } from '$blocks/AppHome/sections/AppSummaryCard/types'
import Last30MinUserChart from '$blocks/AppHome/sections/Last30MinUserChart'
import DailyActiveUserChart from '$blocks/AppHome/sections/DailyActiveUserChart'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'
import Locations from '$blocks/AppHome/sections/Locations'
import Devices from '$blocks/AppHome/sections/Devices'

const AppHome = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { fetchAppHome, appHome } = useAppHomeService()

  const {
    user_count = 0,
    group_count = 0,
    role_count = 0,
    today_active_user_count = 0,
    recent_active_user_counts = [],
    active_users = []
  } = appHome ?? {}

  useEffect(() => {
    if (!appId) return
    fetchAppHome({ application_id: appId })
  }, [appId, fetchAppHome])

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <article className="flex flex-col gap-1.5">
        <PublishAppSetting />

        <article className="grid lg:grid-cols-3 gap-1.5">
          <AppSummaryCard item={ItemType.USER} count={user_count} />
          <AppSummaryCard item={ItemType.ROLE} count={role_count} />
          <AppSummaryCard item={ItemType.GROUP} count={group_count} />
        </article>

        <article className="grid grid-cols-1 lg:grid-cols-2 gap-1.5">
          <Last30MinUserChart recent_active_users={recent_active_user_counts} />

          <DailyActiveUserChart
            active_users={active_users}
            today_active_user_count={today_active_user_count}
          />
        </article>

        <article className="grid grid-cols-1 xl:grid-cols-2 gap-1.5">
          <Locations />

          <Devices />
        </article>
      </article>
    </>
  )
}

export default AppHome
