import { cn, formatNumber } from '$app/utils'
import TodayBadge from '$blocks/AppHome/components/TodayBadge'
import TodayCard from '$blocks/AppHome/components/TodayCard'
import {
  card,
  title,
  defaultTitleFontWeight,
  description
} from '$styles/common.css'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getLast30minsOption } from './utils'
import { TAppHome } from '$services/api'
import { useMemo } from 'react'

interface IProps {
  recent_active_users: TAppHome['recent_active_user_counts']
}

const Last30MinUserChart = ({ recent_active_users }: IProps) => {
  const recent_active_users_count = useMemo(
    () => recent_active_users.reduce((r, c) => r + c, 0),
    [recent_active_users]
  )

  return (
    <article className={cn('flex-1 flex flex-col p-6 gap-6', card)}>
      <article className="flex-1 flex gap-4">
        <article className="flex flex-col gap-1">
          <p className={title['type3']}>ACTIVE USERS IN LAST 30 MINS</p>

          <article className="flex items-center gap-2">
            <p className={defaultTitleFontWeight} style={{ fontSize: 28 }}>
              {formatNumber(recent_active_users_count)}
            </p>
            <TodayBadge />
          </article>

          <p className={description}>
            View active users in the last 30 minutes for real-time engagement
            insights.
          </p>
        </article>

        <TodayCard />
      </article>

      <article className="flex w-full">
        <div style={{ width: '100%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getLast30minsOption(recent_active_users)}
          />
        </div>
      </article>
    </article>
  )
}

export default Last30MinUserChart
