import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import { title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button } from '@genie-fintech/ui/components'
import { Plus } from 'lucide-react'
import AppLogo from '$components/AppLogo'
import { useCallback } from 'react'
import { redirect, ROUTE_NAMES } from '$router/config'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import EnterTransitionBlock from '$components/EnterTransitionBlock'
import { useUserId } from '$layouts/GlobalUserLayout/hooks/useUserId'
import { USER_TYPE } from '$layouts/GlobalUserLayout/types'
import EmptyData from '$components/EmptyData'
import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'
import { appCard } from '$pages/GlobalServiceUser/AccessApps/styles.css'
import { ACCOUNT_TYPE } from '../constants'
import DashboardAdminMessage from '$components/DashboardAdminMessage'

const { colors } = themeVars

const GlobalDashboardUserAccessApps = ({
  userType
}: {
  userType: USER_TYPE
}) => {
  const { userId } = useUserId(userType)

  const accessApps =
    useUserInfoStore(state => state.userInfo?.applications) ?? []
  const user = useUserInfoStore(state => state.userInfo?.type) ?? ''

  const onClickAppCard = useCallback((appId: number) => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [])

  const onGoToAppListing = useCallback(() => {
    redirect(ROUTE_NAMES.APPS)
  }, [])

  const onAddApplication = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP, {
      params: { dashboardUserId: userId }
    })
  }, [userId])

  const isAdmin = user === ACCOUNT_TYPE.ADMIN

  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      {!isAdmin && (
        <MainHeader
          title={`ACCESS ${accessApps.length} APPLICATIONS TO USE`}
          desc="This user can access these applications by related assigned roles and groups."
        />
      )}

      <article className={mainSubContainerStyle}>
        {!isAdmin && (
          <>
            <article className="flex items-center justify-between gap-1">
              <p className={title.six} style={{ color: colors.text.light }}>
                You can add more application to this user.
              </p>

              <Button
                styleVariants={{ size: 'small' }}
                onClick={onAddApplication}
              >
                <Plus size={16} />
                Add Application
              </Button>
            </article>

            {!accessApps.length && <EmptyData type="applications" />}

            <article className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2">
              {accessApps.map(v => {
                return (
                  <button
                    key={v.id}
                    type="button"
                    className={appCard}
                    onClick={() => onClickAppCard(v.id)}
                  >
                    <AppLogo imgUrl={v.logo?.url} brand={v.brand} size="big" />
                    <article className="flex flex-col gap-1">
                      <p className={title.six}>{v.name}</p>
                    </article>
                  </button>
                )
              })}
            </article>
          </>
        )}

        {isAdmin && (
          <article className="flex gap-6 flex-col items-center">
            <DashboardAdminMessage />

            <Button
              styleVariants={{ type: 'text', size: 'small' }}
              onClick={onGoToAppListing}
            >
              Go to Application Listing
            </Button>
          </article>
        )}
      </article>
    </EnterTransitionBlock>
  )
}

export default GlobalDashboardUserAccessApps
