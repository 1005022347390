import { z } from 'zod'

export const schema = z.object({
  country_id: z.string().trim().min(1, 'Required!'),
  name: z.string().trim().min(1, 'Requried!'),
  display_name: z.string().trim().min(1, 'Requried!'),
  group: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  description: z.string().trim(),
  permissions: z.number().array()
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  country_id: '1',
  name: '',
  display_name: '',
  group: { label: '', value: '' },
  description: '',
  permissions: []
}
