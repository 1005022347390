import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { TRouterValues } from '$contexts/RouteContext/types'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'

type TRedirectOptionsParams = Partial<
  Pick<TRouterValues['routeState'], 'params' | 'queryParams' | 'hash'>
> & { replace?: true }

const Navigate = ({
  to,
  options
}: {
  to: ROUTE_NAMES
  options?: TRedirectOptionsParams
}) => {
  const { route } = useRouteSummary()

  useMount(() => {
    redirect(to, {
      replace: options?.replace,
      params: { ...route.params, ...options?.params },
      queryParams: { ...route.queryParams, ...options?.queryParams },
      hash: options?.hash || route.hash
    })
    return
  })

  return null
}

export default Navigate
