import Breadcrumb from '$components/Breadcrumb/v2'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService, useDeleteService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import UserInfo from './UserInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import { ArrowUpRight, ChevronDown, ChevronLeft, Trash2 } from 'lucide-react'
import { Button } from '@genie-fintech/ui/components'
import { useCallback, useRef } from 'react'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { cn } from '$app/utils'
import Header from '$layouts/LayoutWithSidebar/Header'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { title } from '@genie-fintech/ui/style/typography'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TID } from '$services/api'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

const buttonContainerStyle = cn(
  title.six,
  'flex items-center justify-between px-4 py-2'
)

const MenuLinkButton = ({
  label,
  routeName,
  onClick
}: {
  label: string
  routeName: ROUTE_NAMES
  onClick: (routeName: ROUTE_NAMES) => void
}) => {
  return (
    <button
      className={buttonContainerStyle}
      style={{ color: colors.neutral[50] }}
      onClick={() => onClick(routeName)}
    >
      {label}
      <ArrowUpRight size={20} />
    </button>
  )
}

const Line = () => <hr style={{ borderColor: colors.neutral[10] }} />

const HeaderActionButton = ({
  userId,
  onTerminatUser
}: {
  userId: TID
  onTerminatUser: VoidFunction
}) => {
  const onClick = useCallback(
    (routeName: ROUTE_NAMES) => {
      redirect(routeName, {
        params: { serviceUserId: userId }
      })
    },
    [userId]
  )

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <article className="cursor-pointer">
          <Button className="pointer-events-none">
            Actions
            <ChevronDown size={16} />
          </Button>
        </article>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={cn(
            'flex flex-col w-[300px] rounded z-[1]',
            defaultBorder,
            defaultBackground
          )}
          sideOffset={5}
          align="end"
        >
          <article className="flex flex-col gap-2.5 py-2">
            <MenuLinkButton
              label="Access Applications"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS}
              onClick={onClick}
            />

            <MenuLinkButton
              label="View Full Profile"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL}
              onClick={onClick}
            />

            <MenuLinkButton
              label="Devices Login"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DEVICES_LOGIN}
              onClick={onClick}
            />

            <Line />

            <MenuLinkButton
              label="Password Setting"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING}
              onClick={onClick}
            />

            <MenuLinkButton
              label="Multi-Factor Authentication"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_MFA}
              onClick={onClick}
            />

            <Line />

            <MenuLinkButton
              label="Configure Timezone"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL}
              onClick={onClick}
            />

            <MenuLinkButton
              label="Configure Login User Type"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL}
              onClick={onClick}
            />

            <Line />

            <button
              className={buttonContainerStyle}
              style={{ color: colors.danger[markedDefaultKey] }}
              onClick={onTerminatUser}
            >
              Terminate User
              <Trash2 size={20} />
            </button>
          </article>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

const AppUserDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const terminateButtonRef = useRef<HTMLDivElement>(null)

  const { appId, userId } = params

  const { user, fetchAppUserDetail, fetchingAppUserDetail } =
    useAppUserService()

  const { terminateAppUserAsync, terminatingAppUser } = useDeleteService()

  const isUserActive = user?.status === 'active'

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetail({
      application_id: appId,
      user_id: userId
    })
  })

  const onTerminateUser = useCallback(() => {
    if (!appId || !userId) return
    terminateAppUserAsync({ appId, userId })
  }, [appId, userId, terminateAppUserAsync])

  const onAssignRoles = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_ASSIGN_ROLES, { params })
  }, [params])

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USERS, { params })
  }, [params])

  if (fetchingAppUserDetail || !user) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      />

      <Header>
        <article
          className={cn(
            'flex w-full items-center px-6 py-4 gap-3 border-0 border-b',
            defaultBackground,
            defaultBorder
          )}
          style={{ background: colors.area.high }}
        >
          <Button
            styleVariants={{
              category: 'icon',
              kind: 'neutral',
              type: 'outlined',
              size: 'small'
            }}
            onClick={onBack}
          >
            <ChevronLeft size={20} />
          </Button>
          <article className="flex-1">
            <p className={title.two} style={{ color: colors.text.light }}>
              {user.name}
            </p>
          </article>
          <Button
            styleVariants={{
              kind: 'neutral',
              type: 'outlined',
              size: 'small'
            }}
            onClick={onAssignRoles}
          >
            Assign Roles
          </Button>

          <HeaderActionButton
            userId={user.id}
            onTerminatUser={() => terminateButtonRef.current?.click()}
          />
        </article>
      </Header>

      <article className="flex flex-col gap-2 flex-1 relative">
        <UserInfo user={user} />

        {isUserActive && (
          <DangerZone type="app_user">
            <DeleteDoubleConfirmPopUp
              ref={terminateButtonRef}
              item={{ type: 'app_user', name: user.name }}
              onExecute={onTerminateUser}
              loading={terminatingAppUser}
            >
              <Button styleVariants={{ kind: 'danger', type: 'text' }}>
                Terminate User
                <Trash2 size={16} />
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        )}
      </article>
    </>
  )
}

export default AppUserDetail
