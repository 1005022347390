import {
  ChangeEvent,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { AlertTriangle, Trash2 } from 'lucide-react'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { Button, Checkbox, Spinner } from '@genie-fintech/ui/components'
import Asterisk from '$components/Asterisk'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { CheckedState } from '@radix-ui/react-checkbox'
import { TDeleteItemType } from './types'
import { DELETE_INFO } from '$components/DangerZone/constants'

const { colors } = themeVars

const AlertTriangleIcon = () => {
  return (
    <article
      className="inline-flex w-[40px] h-[40px] items-center justify-center rounded-lg border"
      style={{
        background: colors.alphaWarning[1],
        borderColor: colors.warning[20],
        color: colors.warning[markedDefaultKey]
      }}
    >
      <AlertTriangle size={20} />
    </article>
  )
}

interface IProps {
  item: { name: string; type: TDeleteItemType }
  onExecute: VoidFunction
  loading?: boolean
  disabled?: boolean
}
export interface IButtonContainerRef {
  click: () => void
}

const DeleteDoubleConfirmPopUp = forwardRef<
  IButtonContainerRef,
  PropsWithChildren<IProps>
>(({ children, item, onExecute, loading, disabled }, ref) => {
  const buttonContainerRef = useRef<HTMLDivElement>(null)

  const [dialogOpen, setDialogOpen] = useState(false)

  const [isConfirmed, setIsConfirmed] = useState(false)

  const [inputValue, setInputValue] = useState('')

  const [isAgree, setIsAgree] = useState<CheckedState>(false)

  const isMatched = useMemo(
    () => item.name === inputValue,
    [item.name, inputValue]
  )

  const { title, desc } = DELETE_INFO[item.type]

  const isValid = isAgree && isMatched

  const actionName = (() => {
    if (['app_user', 'user'].includes(item.type)) return 'terminate'
    return 'delete'
  })()

  const _actionName = (() => {
    if (actionName === 'terminate') return 'termination'
    return 'deletion'
  })()

  useImperativeHandle(ref, () => {
    return {
      click: () => {
        buttonContainerRef.current?.click()
      }
    }
  }, [])

  const onOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
  }, [])

  const onCheckedChange = useCallback((checked: CheckedState) => {
    setIsAgree(checked)
  }, [])

  const onCancel = useCallback(() => {
    setDialogOpen(false)
    setIsConfirmed(false)
    setInputValue('')
    setIsAgree(false)
  }, [])

  const onConfirm = useCallback(() => {
    setIsConfirmed(true)
  }, [])

  const onProceed = useCallback(async () => {
    if (!isMatched) return
    onExecute()
  }, [isMatched, onExecute])

  return (
    <Dialog.Root open={dialogOpen}>
      <article
        ref={buttonContainerRef}
        className="inline-flex cursor-pointer"
        onClick={disabled ? undefined : onOpenDialog}
      >
        {children}
      </article>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />

        <Dialog.Content
          aria-describedby=""
          className="DialogContent"
          style={{ maxWidth: 480 }}
        >
          <Dialog.Title />

          {!isConfirmed && (
            <article className="flex flex-col p-8 gap-6">
              <AlertTriangleIcon />

              <article className="flex flex-col gap-4">
                <p className="text-xl font-semibold">Are you sure?</p>

                <p className="text-sm" style={{ color: colors.neutral[70] }}>
                  {desc}
                </p>
              </article>
            </article>
          )}

          {isConfirmed && (
            <article className="flex flex-col p-8 gap-5">
              <AlertTriangleIcon />

              <article className="flex flex-col gap-6">
                <article className="flex flex-col gap-1">
                  <p className="font-semibold capitalize">{title}</p>
                  <p className="text-xs" style={{ color: colors.neutral[50] }}>
                    {desc}
                  </p>
                </article>

                <article className="flex flex-col gap-2">
                  <p className="text-sm font-medium">
                    <span>Type "</span>
                    <span style={{ color: colors.danger[markedDefaultKey] }}>
                      {` ${item.name} `}
                    </span>
                    <span>
                      " to proceed {_actionName}
                      <Asterisk />
                    </span>
                  </p>

                  <BaseText
                    inputProps={{
                      value: inputValue,
                      onChange: onInputChange
                    }}
                  />

                  <p className="text-xs" style={{ color: colors.neutral[70] }}>
                    Enter the exact phrase (case sensitive).
                  </p>
                </article>

                <article className="flex gap-2 items-center">
                  <Checkbox boxProps={{ checked: isAgree, onCheckedChange }} />

                  <p className="text-xs" style={{ color: colors.neutral[60] }}>
                    {`I understand all data will be lost if I ${actionName} the ${item.type}`}
                  </p>
                </article>
              </article>
            </article>
          )}

          <footer className="flex p-4 gap-2 justify-end">
            <Button
              styleVariants={{
                kind: 'neutral',
                type: 'outlined',
                size: 'small'
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            {!isConfirmed && (
              <Button styleVariants={{ size: 'small' }} onClick={onConfirm}>
                Confirm
              </Button>
            )}

            {isConfirmed && (
              <Button
                disabled={!isValid || loading}
                styleVariants={{ kind: 'danger', size: 'small' }}
                className="capitalize"
                onClick={onProceed}
              >
                {actionName}
                {loading ? <Spinner /> : <Trash2 size={16} />}
              </Button>
            )}
          </footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
})

export default DeleteDoubleConfirmPopUp
