import { body, title } from '@genie-fintech/ui/style/typography'
import {
  backbuttonStyle,
  container,
  doubleConfirmFooterContainer,
  doubleConfirmMainContainer,
  doubleConfirmSelectedTimezoneContainer,
  emptyData,
  flexDirectionColumn,
  optionContainer,
  optionItem,
  searchInputContainerStyle,
  searchInputStyle
} from './styles.css'
import { description } from '$styles/common.css'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { ChevronDown } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce, useToggle } from 'ahooks'
import * as Dialog from '@radix-ui/react-dialog'
import { Icon } from '@genie-fintech/ui/icons'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { TTimezone } from '$contexts/AuthContext/types'
import { Button, Spinner } from '@genie-fintech/ui/components'
import WarningIcon from '$components/WarningIcon'

const { colors } = themeVars

interface IProps {
  timezone: string
  loading?: boolean
  onUpdateTimezone: (tz: TTimezone[number], callBackFn: VoidFunction) => void
}

const TimezoneSetting = ({ timezone, loading, onUpdateTimezone }: IProps) => {
  const [selectedTimezone, setSelectedTimezone] = useState<TTimezone[number]>()

  const [searchValue, setSearchValue] = useState('')

  const { timezones } = useAuthContext()

  const [modalOpen, { toggle }] = useToggle()

  const debouncedSearch = useDebounce(searchValue, { wait: 500 })

  const filteredTimezones = useMemo(
    () =>
      timezones.filter(t =>
        t.value
          .toLocaleLowerCase()
          .includes(debouncedSearch.toLocaleLowerCase())
      ),
    [timezones, debouncedSearch]
  )

  const onModalOpenChange = useCallback(() => {
    if (!selectedTimezone) toggle()
  }, [selectedTimezone, toggle])

  const onBack = useCallback(() => {
    setSelectedTimezone(undefined)
  }, [])

  const onCancel = useCallback(() => {
    setSelectedTimezone(undefined)
    setSearchValue('')
    toggle()
  }, [toggle])

  const onConfirm = useCallback(() => {
    if (!selectedTimezone) return
    onUpdateTimezone(selectedTimezone, onCancel)
  }, [onUpdateTimezone, selectedTimezone, onCancel])

  return (
    <>
      <article className={container}>
        <article
          className={flexDirectionColumn}
          style={{ flex: 1, gap: 4, minWidth: 400 }}
        >
          <p className={title.two}>CONFIGURE TIMEZONE</p>
          <p className={description}>
            The system will provide reports aligned with your selected timezone.
          </p>
        </article>

        <BaseText
          label="Preferred Timezone"
          required
          affix={{ post: <ChevronDown size={20} /> }}
          containerProps={{ style: { maxWidth: 400, cursor: 'pointer' } }}
          inputProps={{
            readOnly: true,
            onFocus: toggle,
            value: timezones.find(t => t.key === timezone)?.value ?? ''
          }}
        />
      </article>

      <Dialog.Root open={modalOpen} onOpenChange={onModalOpenChange}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content
            className="DialogContent flex flex-col max-w-[480px] h-[400px]"
            aria-describedby=""
          >
            <Dialog.Title className="hidden" />

            {!selectedTimezone && (
              <>
                <article className={searchInputContainerStyle}>
                  <BaseText
                    affix={{
                      pre: <Icon namespace="Search" width={20} />,
                      post: searchValue && (
                        <button onClick={() => setSearchValue('')}>
                          <Icon namespace="Cross" width={20} />
                        </button>
                      )
                    }}
                    elementControlProps={{ className: searchInputStyle }}
                    inputProps={{
                      value: searchValue,
                      onChange: e => setSearchValue(e.currentTarget.value)
                    }}
                  />
                </article>

                <article className={optionContainer}>
                  {!filteredTimezones.length && (
                    <article className={emptyData}>No Data</article>
                  )}

                  {filteredTimezones.map(v => {
                    const isSelected = v.key === timezone

                    return (
                      <article
                        key={v.key}
                        className={optionItem}
                        onClick={() => setSelectedTimezone(v)}
                      >
                        <span
                          className={title.six}
                          style={{ color: colors.text.light, flex: 1 }}
                        >
                          {v.value}
                        </span>

                        {isSelected && (
                          <Icon
                            namespace="Check"
                            color="primary.default"
                            width={20}
                          />
                        )}
                      </article>
                    )
                  })}
                </article>
              </>
            )}

            {selectedTimezone && (
              <article className={flexDirectionColumn} style={{ flex: 1 }}>
                <main className={doubleConfirmMainContainer}>
                  <article>
                    <button
                      className={backbuttonStyle}
                      onClick={onBack}
                      disabled={loading}
                    >
                      <Icon namespace="Backward" width={16} />
                      Back
                    </button>
                  </article>

                  <WarningIcon />

                  <article className={flexDirectionColumn} style={{ gap: 4 }}>
                    <h4 className={title.four}>
                      You are about to change the timezone!
                    </h4>

                    <p
                      className={body.three}
                      style={{ color: colors.neutral[70] }}
                    >
                      You are changing the timezone to...
                    </p>
                  </article>

                  <article className={doubleConfirmSelectedTimezoneContainer}>
                    {selectedTimezone.value}
                  </article>
                </main>

                <footer className={doubleConfirmFooterContainer}>
                  <Button
                    onClick={onCancel}
                    disabled={loading}
                    styleVariants={{
                      type: 'outlined',
                      kind: 'neutral',
                      size: 'small'
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={onConfirm}
                    styleVariants={{ size: 'small' }}
                    disabled={loading}
                  >
                    {loading && <Spinner />}
                    Confirm
                  </Button>
                </footer>
              </article>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default TimezoneSetting
