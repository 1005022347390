import { SERVICE_USER_TYPE, TPasswordSetting } from '$services/api'
import { Avatar, Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { body, title } from '@genie-fintech/ui/style/typography'
import * as Dialog from '@radix-ui/react-dialog'
import { useCallback, useMemo, useState } from 'react'
import {
  attributeValueCell,
  loginUserTypeTransitionContainer,
  modifyRulesContainer,
  settingTableContainer,
  valueCell
} from './styles.css'
import { AlertTriangle, Check } from 'lucide-react'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { usePasswordSettingValues } from '$hooks/services'
import { pluralize } from '$app/utils'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

enum YES_NO {
  YES = 'yes',
  NO = 'no'
}

interface IProps {
  modalOpen: boolean
  currentLoginUserType: SERVICE_USER_TYPE
  loading?: boolean
  onCancel: VoidFunction
  onContinue: (
    newLoginUserType: SERVICE_USER_TYPE,
    modify_login_rules: boolean,
    callbackFn?: VoidFunction
  ) => void
  currentPasswordSettingValues?: ReturnType<
    typeof usePasswordSettingValues
  >['currentPasswordSettingValues']
  defaultPasswordSettingValues: ReturnType<
    typeof usePasswordSettingValues
  >['defaultPasswordSettingValues']
}

const tabIndexProps = { tabIndex: -1 } // NOTE: To avoid autoFocus

const LoginUserTypeState = ({
  loginUserType,
  isActive
}: {
  loginUserType: SERVICE_USER_TYPE
  isActive?: boolean
}) => {
  return (
    <article className="inline-flex gap-2 items-center">
      <Avatar
        size={40}
        type={isActive ? 'filled' : 'default'}
        active={isActive}
      />

      <p
        className={title.six}
        style={{ color: colors.text.light, textTransform: 'capitalize' }}
      >
        {loginUserType} user
      </p>
    </article>
  )
}

const TransitionArrow = () => (
  <svg
    width="107"
    height="16"
    viewBox="0 0 107 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.166667 8C0.166667 10.9455 2.55448 13.3333 5.5 13.3333C8.44552 13.3333 10.8333 10.9455 10.8333 8C10.8333 5.05448 8.44552 2.66667 5.5 2.66667C2.55448 2.66667 0.166667 5.05448 0.166667 8ZM106.207 8.70711C106.598 8.31658 106.598 7.68342 106.207 7.29289L99.8431 0.928932C99.4526 0.538408 98.8195 0.538408 98.4289 0.928932C98.0384 1.31946 98.0384 1.95262 98.4289 2.34315L104.086 8L98.4289 13.6569C98.0384 14.0474 98.0384 14.6805 98.4289 15.0711C98.8195 15.4616 99.4526 15.4616 99.8431 15.0711L106.207 8.70711ZM5.5 9H105.5V7H5.5V9Z"
      fill={colors.primary[markedDefaultKey]}
    />
  </svg>
)

const CircleCheck = () => (
  <span
    className="w-9 h-9 inline-flex justify-center items-center rounded-full flex-shrink-0"
    style={{ background: colors.success[0] }}
  >
    <span
      className="w-[22px] h-[22px] inline-flex justify-center items-center rounded-full"
      style={{ background: colors.success[markedDefaultKey] }}
    >
      <Check size={11} style={{ color: colors.absolute.light }} />
    </span>
  </span>
)

const HeaderCellWithIcon = ({
  label,
  isActive
}: {
  label: string
  isActive: boolean
}) => {
  return (
    <article className="inline-flex items-center gap-2">
      {label}
      {isActive && <CircleCheck />}
    </article>
  )
}

const SettingTable = ({
  isModifyLoginRules,
  currentValues,
  newValues
}: {
  isModifyLoginRules: boolean
  currentValues?: TPasswordSetting
  newValues?: TPasswordSetting
}) => {
  const tableData = [
    [
      'PASSWORD EXPIRY EVERY',
      currentValues?.password_expiration_days
        ? pluralize(currentValues?.password_expiration_days, 'day')
        : 'No Expired',
      newValues?.password_expiration_days
        ? pluralize(newValues?.password_expiration_days, 'day')
        : 'No Expired'
    ],
    [
      'DAILY LOGIN REQUIRED',
      currentValues?.access_token_expires_in
        ? pluralize(currentValues?.access_token_expires_in, 'day')
        : 'No Required',
      newValues?.access_token_expires_in
        ? pluralize(newValues?.access_token_expires_in, 'day')
        : 'No Required'
    ],
    [
      'PREVIOUS PASSWORDS CANNOT BE REUSED',
      pluralize(currentValues?.password_history_depth ?? 0, 'Password'),
      pluralize(newValues?.password_history_depth ?? 0, 'Password')
    ],
    [
      'MULTI-FACTOR AUTHENTICATION',
      currentValues?.mfa_enabled ? 'Enabled' : 'Disabled',
      newValues?.mfa_enabled ? 'Enabled' : 'Disabled'
    ]
  ]
  return (
    <article className={customTableContainer}>
      <main className={tableContainerInner}>
        <table
          className={table({ separator: 'line' })}
          style={{ tableLayout: 'fixed' }}
        >
          <thead className={customTableHead}>
            <tr className={tableRow}>
              <th className={customTableCell}>ATTRIBUTE</th>
              <th className={customTableCell}>
                <HeaderCellWithIcon
                  label="CURRENT VALUE"
                  isActive={!isModifyLoginRules}
                />
              </th>
              <th className={customTableCell}>
                <HeaderCellWithIcon
                  label="NEW VALUE"
                  isActive={isModifyLoginRules}
                />
              </th>
            </tr>
          </thead>
          <tbody className={tableBody}>
            {tableData.map((row, key) => (
              <tr className={tableRow} key={key}>
                <td className={attributeValueCell}>{row[0]}</td>
                <td
                  className={valueCell}
                  data-active={trueOrUndefined(!isModifyLoginRules)}
                >
                  {row[1]}
                </td>
                <td
                  className={valueCell}
                  data-active={trueOrUndefined(isModifyLoginRules)}
                >
                  {row[2]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </article>
  )
}

const LoginUserTypeDialog = ({
  modalOpen,
  currentLoginUserType,
  onCancel,
  onContinue,
  loading,
  currentPasswordSettingValues,
  defaultPasswordSettingValues
}: IProps) => {
  const [isModifyLoginRules, setIsModifyLoginRules] = useState(true)

  const newLoginUserType = useMemo(() => {
    if (currentLoginUserType === SERVICE_USER_TYPE.INTERNAL)
      return SERVICE_USER_TYPE.EXTERNAL
    return SERVICE_USER_TYPE.INTERNAL
  }, [currentLoginUserType])

  const newSettingValues = useMemo(() => {
    return defaultPasswordSettingValues.find(d => d.type === newLoginUserType)
  }, [defaultPasswordSettingValues, newLoginUserType])

  const onChangeModifyLoginRules = useCallback((value: string) => {
    setIsModifyLoginRules(value === YES_NO.YES)
  }, [])

  const resetModifyLoginRules = useCallback(() => {
    setIsModifyLoginRules(true)
  }, [])

  const _onCancel = useCallback(() => {
    onCancel()
    resetModifyLoginRules()
  }, [resetModifyLoginRules, onCancel])

  const _onContinue = useCallback(() => {
    onContinue(newLoginUserType, isModifyLoginRules, _onCancel)
  }, [onContinue, newLoginUserType, isModifyLoginRules, _onCancel])

  return (
    <Dialog.Root open={modalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent flex flex-col max-w-[1200px]"
          aria-describedby=""
        >
          <Dialog.Title className="hidden" />

          <main className="flex flex-col gap-6 p-8">
            <header className="flex flex-col gap-1">
              <p className={title.four}>
                You are about to change the login user type{' '}
                {currentLoginUserType} to {newLoginUserType}!
              </p>
              <p className={body.three} style={{ color: colors.neutral[70] }}>
                You can use the current login rules if you don't want to modify.
              </p>
            </header>

            <article className="flex flex-col gap-2">
              <article className={loginUserTypeTransitionContainer}>
                <LoginUserTypeState loginUserType={currentLoginUserType} />
                <TransitionArrow />
                <LoginUserTypeState loginUserType={newLoginUserType} isActive />
              </article>

              <article className={settingTableContainer}>
                <SettingTable
                  isModifyLoginRules={isModifyLoginRules}
                  currentValues={currentPasswordSettingValues}
                  newValues={newSettingValues}
                />
              </article>

              <article className={modifyRulesContainer}>
                <article
                  className="inline-flex border rounded-lg p-2.5"
                  style={{
                    borderColor: colors.warning[20],
                    color: colors.warning[markedDefaultKey],
                    background: colors.alphaWarning[1]
                  }}
                >
                  <AlertTriangle />
                </article>
                <article className="flex-1 flex flex-col gap-1">
                  <p className={title.two}>
                    DO YOU WANT TO MODIFY THE LOGIN RULES?
                  </p>
                  <p
                    className={body.three}
                    style={{ color: colors.neutral[70] }}
                  >
                    If NO, the current rules will not be changed.
                  </p>
                </article>
                <article>
                  <Tabs.Root
                    value={isModifyLoginRules ? YES_NO.YES : YES_NO.NO}
                    onValueChange={onChangeModifyLoginRules}
                    format={{ type: 'segmented' }}
                  >
                    <Tabs.List styleVariants={{ hAlign: 'right' }}>
                      <Tabs.Trigger value={YES_NO.NO}>NO</Tabs.Trigger>
                      <Tabs.Trigger value={YES_NO.YES}>YES</Tabs.Trigger>
                    </Tabs.List>
                  </Tabs.Root>
                </article>
              </article>

              {!isModifyLoginRules && (
                <article
                  className="flex items-center border rounded-lg px-4 py-3"
                  style={{
                    borderColor: colors.warning[20],
                    background: colors.alphaWarning[1]
                  }}
                >
                  <p className={title.six} style={{ color: colors.text.light }}>
                    This user sso login rules will not be modified!
                  </p>
                </article>
              )}
            </article>
          </main>

          <footer className="flex items-center justify-end p-4 gap-2">
            <Button
              onClick={_onCancel}
              styleVariants={{
                type: 'outlined',
                kind: 'neutral',
                size: 'small'
              }}
              disabled={loading}
              {...tabIndexProps}
            >
              Cancel
            </Button>
            <Button
              onClick={_onContinue}
              styleVariants={{ size: 'small' }}
              disabled={loading}
              {...tabIndexProps}
            >
              {loading && <Spinner />}
              Continue
            </Button>
          </footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default LoginUserTypeDialog
