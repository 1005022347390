import {
  getServiceUserAccessedApps,
  getServiceUserInfo,
  getServiceUserPasswordSetting,
  putServiceUser,
  postServiceUserPasswordSetting,
  postServiceUserForceLogout,
  postServiceUserGeneratePasswordLink,
  deleteServiceUserGeneratePasswordLink,
  getServiceUserMFASetting,
  postServiceUserEnforceMFASetting,
  getCurrentOTP,
  putServiceUserType,
  putTimezone,
  postServiceUserForceUpdate
} from '$services/api'
import { useRequest } from 'ahooks'

export const useGlobalServiceUserService = () => {
  const {
    run: fetchServiceUserInfo,
    runAsync: fetchServiceUserInfoAsync,
    loading: fetchingServiceUserInfo,
    data: serviceUserInfoData
  } = useRequest(getServiceUserInfo, { manual: true })

  const {
    run: fetchAccessApps,
    loading: fetchingAccessApps,
    data: accessAppsData
  } = useRequest(getServiceUserAccessedApps, { manual: true })

  const { runAsync: updateServiceUserAsync, loading: updatingServiceUser } =
    useRequest(putServiceUser, {
      manual: true
    })

  const {
    runAsync: fetchServiceUserPasswordSettingAsync,
    loading: fetchingServiceUserPasswordSetting,
    data: serviceUserPasswordSettingData
  } = useRequest(getServiceUserPasswordSetting, {
    manual: true
  })

  const {
    runAsync: saveServiceUserPasswordSettingAsync,
    loading: savingServiceUserPasswordSetting
  } = useRequest(postServiceUserPasswordSetting, {
    manual: true
  })

  const {
    run: makeforceLogoutServiceUser,
    runAsync: makeforceLogoutServiceUserAsync,
    loading: makingforceLogoutServiceUser
  } = useRequest(postServiceUserForceLogout, {
    manual: true
  })

  const {
    run: updateforceUpdateServiceUser,
    runAsync: updateforceUpdateServiceUserAsync,
    loading: updatingforceUpdateServiceUser
  } = useRequest(postServiceUserForceUpdate, {
    manual: true
  })

  const {
    runAsync: generateResetPasswordLinkAsync,
    loading: generatingResetPasswordLink
  } = useRequest(postServiceUserGeneratePasswordLink, {
    manual: true
  })

  const {
    runAsync: deleteResetPasswordLinkAsync,
    loading: deletingResetPasswordLink
  } = useRequest(deleteServiceUserGeneratePasswordLink, {
    manual: true
  })

  const {
    run: fetchServiceUserMFASetting,
    loading: fetchingServiceUserMFASetting,
    data: userMFAData
  } = useRequest(getServiceUserMFASetting, { manual: true })

  const { runAsync: enforceMFAAsync, loading: enforcingMFA } = useRequest(
    postServiceUserEnforceMFASetting,
    {
      manual: true
    }
  )

  const {
    run: fetchCurrentOTP,
    loading: fetchingCurrentOTP,
    data: currentOTPData
  } = useRequest(getCurrentOTP, {
    manual: true
  })

  const {
    runAsync: updateServiceUserTypeAsync,
    loading: updatingServiceUserType
  } = useRequest(putServiceUserType, { manual: true })

  const {
    runAsync: updateServiceUserTimezoneAsync,
    loading: updatingServiceUserTimezone
  } = useRequest(putTimezone, { manual: true })

  return {
    fetchServiceUserInfo,
    fetchServiceUserInfoAsync,
    fetchingServiceUserInfo,
    serviceUserInfo: serviceUserInfoData?.data,

    fetchAccessApps,
    fetchingAccessApps,
    accessApps: accessAppsData?.data.applications ?? [],

    updateServiceUserAsync,
    updatingServiceUser,

    fetchServiceUserPasswordSettingAsync,
    fetchingServiceUserPasswordSetting,
    passwordSetting: serviceUserPasswordSettingData?.data,

    saveServiceUserPasswordSettingAsync,
    savingServiceUserPasswordSetting,

    makeforceLogoutServiceUser,
    makeforceLogoutServiceUserAsync,
    makingforceLogoutServiceUser,

    updateforceUpdateServiceUser,
    updateforceUpdateServiceUserAsync,
    updatingforceUpdateServiceUser,

    generateResetPasswordLinkAsync,
    generatingResetPasswordLink,

    deleteResetPasswordLinkAsync,
    deletingResetPasswordLink,

    enforceMFAAsync,
    enforcingMFA,

    fetchServiceUserMFASetting,
    fetchingServiceUserMFASetting,
    user_mfa: userMFAData?.data,

    fetchCurrentOTP,
    fetchingCurrentOTP,
    currentOTP: currentOTPData?.data,

    updateServiceUserTypeAsync,
    updatingServiceUserType,

    updateServiceUserTimezoneAsync,
    updatingServiceUserTimezone
  }
}
