import { getPhone } from '$app/utils'
import EnterTransitionBlock from '$components/EnterTransitionBlock'
import Loading from '$components/Loading'
import Select from '$components/Select'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useGlobalDashboardUserService } from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount, useToggle } from 'ahooks'
import { useCallback } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  role: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  type: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  })
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  type: { label: '', value: '' },
  role: { label: '', value: '' },
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: ''
}

const Info = ({ label, value }: { label: string; value: string }) => {
  return (
    <article className="flex flex-col gap-1">
      <p className="text-xs font-medium text-[--colors-text-disabled]">
        {label}
      </p>
      <p className="text-sm font-medium">{value}</p>
    </article>
  )
}

const GlobalDashboardUserInfo = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { dashboardUserId } = params

  const [isEditMode, { setLeft: setEditFalse, setRight: setEditTrue }] =
    useToggle()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const {
    fetchUserRoleSelect,
    fetchUserTypeSelect,
    fetchGlobalDashboardUserDetail,
    dashboardUser,
    filter_user_roles_options,
    user_roles_options,
    user_types_options,
    updateGlobalDashboardUserAsync,
    updatingGlobalDashboardUser
  } = useGlobalDashboardUserService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  useMount(() => {
    if (!dashboardUserId) return
    fetchUserRoleSelect()
    fetchUserTypeSelect()
    fetchGlobalDashboardUserDetail(dashboardUserId)
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue
  } = methods

  const selectedAccountType = useWatch({ name: 'type.value', control })

  const onEdit = useCallback(() => {
    const { phone_code, phone_no, role, type } = dashboardUser ?? {}
    const modifiedRole =
      user_roles_options.find(d => d.value === role) ?? DEFAULT_FORM_VALUES.role
    const modifiedType =
      user_types_options.find(d => d.value === type) ?? DEFAULT_FORM_VALUES.type
    reset({
      ...dashboardUser,
      phone_no: phone_no ?? '',
      phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
      role: modifiedRole,
      type: modifiedType
    })
    setEditTrue()
  }, [
    setEditTrue,
    dashboardUser,
    user_roles_options,
    user_types_options,
    reset
  ])

  const onCancel = useCallback(() => {
    setEditFalse()
    reset(DEFAULT_FORM_VALUES)
  }, [setEditFalse, reset])

  const onSubmit = useCallback(
    ({ type, role, phone_code, ...rest }: TFormValues) => {
      if (!dashboardUserId) return

      const payload = {
        ...rest,
        phone_code: phone_code.value,
        role: role.value,
        type: type.value
      }

      updateGlobalDashboardUserAsync(dashboardUserId, payload).then(onCancel)
    },
    [dashboardUserId, updateGlobalDashboardUserAsync, onCancel]
  )

  if (!dashboardUser) return <Loading />

  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      <MainHeader
        title="USER DETAILS"
        desc="Manage and review user accounts for efficient system administration."
      />

      <article className={mainSubContainerStyle}>
        <form
          className="grid md:grid-cols-2 gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <article className="col-span-2 flex items-center gap-2 justify-between">
            <article className="flex flex-col gap-2">
              <p className="font-semibold">Personal Details</p>

              <p className="text-xs text-[--colors-neutral-50]">
                Your personal details can be editable on Carro SSO.
              </p>
            </article>

            <article className="flex items-center gap-2">
              {!isEditMode && (
                <Button
                  styleVariants={{ kind: 'neutral', type: 'outlined' }}
                  onClick={onEdit}
                >
                  Edit
                </Button>
              )}

              {isEditMode && (
                <>
                  <Button
                    disabled={updatingGlobalDashboardUser}
                    styleVariants={{
                      kind: 'neutral',
                      type: 'outlined'
                    }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!isDirty || updatingGlobalDashboardUser}
                    type="submit"
                  >
                    Save
                  </Button>
                </>
              )}
            </article>
          </article>
          {isEditMode ? (
            <BaseText control={control} name="name" label="Name" required />
          ) : (
            <Info label="Name" value={dashboardUser.name} />
          )}

          {isEditMode ? (
            <BaseText control={control} name="email" label="Email" required />
          ) : (
            <Info label="Email" value={dashboardUser.email} />
          )}

          {isEditMode ? (
            <article className="relative">
              <BaseText
                control={control}
                name="phone_no"
                label="Phone Number"
                inputProps={{ className: 'pl-[100px]' }}
              />

              <article className="absolute bottom-0 left-0 w-[100px]">
                <Controller
                  name="phone_code"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        options={phoneOptions}
                        type="sub"
                      />
                    )
                  }}
                />
              </article>
            </article>
          ) : (
            <Info
              label="Phone Number"
              value={getPhone(dashboardUser.phone_code, dashboardUser.phone_no)}
            />
          )}

          {isEditMode ? (
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <article className="flex flex-col gap-y-1">
                    <label className="text-sm font-medium">Account Type</label>
                    <Select
                      {...field}
                      options={user_types_options}
                      onChange={value => {
                        field.onChange(value)
                        setValue('role', DEFAULT_FORM_VALUES.role)
                      }}
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <p className="text-xs text-[--colors-danger-default]">
                        {error.message}
                      </p>
                    )}
                  </article>
                )
              }}
            />
          ) : (
            <Info
              label="Account Type"
              value={
                user_types_options.find(d => d.value === dashboardUser.type)
                  ?.label ?? ''
              }
            />
          )}

          {isEditMode ? (
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <article className="flex flex-col gap-y-1">
                    <label className="text-sm font-medium">Account Role</label>
                    <Select
                      {...field}
                      options={filter_user_roles_options(selectedAccountType)}
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <p className="text-xs text-[--colors-danger-default]">
                        {error.message}
                      </p>
                    )}
                  </article>
                )
              }}
            />
          ) : (
            <Info
              label="Account Role"
              value={
                user_roles_options.find(d => d.value === dashboardUser.role)
                  ?.label ?? ''
              }
            />
          )}
        </form>
      </article>
    </EnterTransitionBlock>
  )
}

export default GlobalDashboardUserInfo
