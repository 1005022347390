import { cn, getDateOfBirth, getPhone } from '$app/utils'
import CountryBadge from '$components/CountryBadge'
import LoginUserType from '$components/LoginUserType'
import { TAppUserDetail } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  defaultBorder
} from '$styles/common.css'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { capitalize } from 'lodash-es'
import { FC, PropsWithChildren } from 'react'

const { colors } = themeVars

const columns: { key: 'name' | 'country' | 'group'; value: string }[] = [
  { key: 'name', value: 'ROLE NAME' },
  { key: 'country', value: 'COUNTRY' },
  { key: 'group', value: 'GROUP' }
]

interface IProps {
  user: TAppUserDetail
}

const Label: FC<PropsWithChildren> = ({ children }) => {
  return (
    <label
      className="font-medium text-xs"
      style={{ color: colors.neutral[60] }}
    >
      {children}
    </label>
  )
}

const Value: FC<PropsWithChildren> = ({ children }) => {
  return (
    <p className="font-semibold text-sm" style={{ color: colors.text.light }}>
      {children}
    </p>
  )
}

const UserInfo = ({ user }: IProps) => {
  return (
    <>
      <article
        className={cn(
          'flex flex-col rounded-lg',
          defaultBackground,
          defaultBorder
        )}
      >
        <article className="flex flex-col p-8 gap-6">
          <article className="flex flex-col gap-1">
            <p className="font-semibold">USER INFO</p>
            <p className="text-xs" style={{ color: colors.neutral[50] }}>
              Manage and review user accounts for efficient system
              administration.
            </p>
          </article>

          <article className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <article className="flex flex-col gap-0.5">
              <Label>Name</Label>
              <Value>{user.name}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Date of Birth</Label>
              <Value>{getDateOfBirth(user.date_of_birth)}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Gender</Label>
              <Value>{capitalize(user.gender)}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Email Address</Label>
              <Value>{user.email}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Phone Number</Label>
              <Value>{getPhone(user.phone_code, user.phone_no)}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Address</Label>
              <Value>{user.address}</Value>
            </article>
          </article>
        </article>
      </article>

      <LoginUserType value={user.user_type} showBadge />

      <article
        className={cn(
          'flex flex-col rounded-lg p-8',
          defaultBackground,
          defaultBorder
        )}
      >
        <article className="flex flex-col gap-6">
          <article className="flex flex-col gap-1">
            <p className="font-semibold">ROLES, REGIONS & GROUPS</p>
            <p className="text-xs" style={{ color: colors.neutral[50] }}>
              View the assigned group and region for each role to ensure proper
              access and alignment.
            </p>
          </article>

          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      NO
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={tableBody}>
                  {user.roles.map((data, rowKey) => (
                    <tr key={rowKey} className={tableRow}>
                      <td className={customTableCell}>{rowKey + 1}</td>
                      {columns.map((col, colKey) => (
                        <td key={colKey} className={customTableCell}>
                          {(() => {
                            if (col.key === 'country') {
                              return <CountryBadge name={data.country.name} />
                            }

                            if (col.key === 'group') {
                              return data.group.name
                            }

                            return data[col.key] ?? 'N/A'
                          })()}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {!user.roles.length && (
                    <tr className={tableRow}>
                      <td
                        colSpan={columns.length + 1}
                        style={{ textAlign: 'center' }}
                        className={customTableCell}
                      >
                        NO DATA
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </main>
          </article>
        </article>
      </article>
    </>
  )
}

export default UserInfo
