import { WEBHOOK_EVENT } from '$services/api'
import { create } from 'zustand'

interface IWebhookEventStore {
  webhookEvents: WEBHOOK_EVENT[]
  updateWebhookEvents: (
    webhookEvents: IWebhookEventStore['webhookEvents']
  ) => void
}

export const useWebhookEventStore = create<IWebhookEventStore>()(set => ({
  webhookEvents: [
    {
      id: '9e054112-ca21-4f04-9c71-f6179c36204d',
      webhook_event_category_id: '9e0540f5-bcd9-4489-b1f3-979c6f2d611b',
      event_type: 'user_email_changed',
      name: 'User Email Changed',
      description: 'User email changed event',
      created_at: '2025-01-21T10:07:17.000000Z',
      updated_at: '2025-01-21T10:07:17.000000Z'
    },
    {
      id: '9e054112-c9ac-4404-842d-78f0a1f03324',
      webhook_event_category_id: '9e0540f5-bcd9-4489-b1f3-979c6f2d611b',
      event_type: 'user_logout',
      name: 'User Logout',
      description: 'User logout event',
      created_at: '2025-01-21T10:07:17.000000Z',
      updated_at: '2025-01-21T10:07:17.000000Z'
    },
    {
      id: '9e054112-cae6-4309-bed9-090b39359ea1',
      webhook_event_category_id: '9e0540f5-bcd9-4489-b1f3-979c6f2d611b',
      event_type: 'user_password_changed',
      name: 'User Password Changed',
      description: 'User password changed event',
      created_at: '2025-01-21T10:07:17.000000Z',
      updated_at: '2025-01-21T10:07:17.000000Z'
    },
    {
      id: '9e054112-ca8a-4f00-a2b5-aa47a9bac979',
      webhook_event_category_id: '9e0540f5-bcd9-4489-b1f3-979c6f2d611b',
      event_type: 'user_phone_changed',
      name: 'User Phone Changed',
      description: 'User phone changed event',
      created_at: '2025-01-21T10:07:17.000000Z',
      updated_at: '2025-01-21T10:07:17.000000Z'
    }
  ],
  updateWebhookEvents: webhookEvents => set({ webhookEvents })
}))
