import VisitorCard from '$blocks/AppHome/components/VisitorCard'
import VisitorList from '$blocks/AppHome/components/VisitorList'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppHomeService } from '$hooks/services'
import { FilterTypeVisitors } from '$services/api'
import {
  AlertTriangle,
  CodeXml,
  LaptopMinimal,
  Loader,
  LucideIcon,
  MonitorSmartphone,
  Smartphone,
  Tablet
} from 'lucide-react'
import { useEffect, useState } from 'react'

const tabs: { label: string; value: FilterTypeVisitors }[] = [
  { label: 'Device', value: 'device' },
  { label: 'Browser', value: 'browser' },
  { label: 'OS', value: 'os' }
]

type TDevices =
  | 'desktop'
  | 'mobile'
  | 'tablet'
  | 'api-client-tools'
  | 'robot'
  | 'unknown'

const Icons: Record<TDevices, LucideIcon> = {
  desktop: LaptopMinimal,
  mobile: Smartphone,
  tablet: Tablet,
  'api-client-tools': CodeXml,
  robot: AlertTriangle,
  unknown: MonitorSmartphone
}

const Devices = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const [activeTab, setActiveTab] = useState(tabs[0].value)

  const {
    fetchVisitorsByDevices,
    fetchingVisitorsByLocation,
    visitors_by_devices
  } = useAppHomeService()

  useEffect(() => {
    if (!appId) return
    fetchVisitorsByDevices({
      application_id: appId,
      group_by: activeTab as FilterTypeVisitors
    })
  }, [appId, fetchVisitorsByDevices, activeTab])

  const formattedList = visitors_by_devices.map(v => ({
    ...v,
    Icon: activeTab === 'device' ? Icons[v.name as TDevices] : undefined
  }))

  return (
    <VisitorCard
      header="Device Logs"
      tabs={tabs}
      currentTab={activeTab}
      onChangeTab={tab => setActiveTab(tab as FilterTypeVisitors)}
    >
      {fetchingVisitorsByLocation && <Loader />}

      <VisitorList
        title={tabs.find(d => d.value === activeTab)?.label ?? ''}
        data={formattedList}
      />
    </VisitorCard>
  )
}

export default Devices
