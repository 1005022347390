import EnterTransitionBlock from '$components/EnterTransitionBlock'
import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import { useUserId } from '$layouts/GlobalUserLayout/hooks/useUserId'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { USER_TYPE } from '$layouts/GlobalUserLayout/types'
import { redirect, ROUTE_NAMES } from '$router/config'
import { description } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { title } from '@genie-fintech/ui/style/typography'
import { Lock } from 'lucide-react'
import { useCallback } from 'react'

const { colors } = themeVars

const DashboardUserPasswordSetting = ({
  userType
}: {
  userType: USER_TYPE
}) => {
  const { userId } = useUserId(userType)

  const onUpdatePassword = useCallback(() => {
    redirect(ROUTE_NAMES.UPDATE_PASSWORD, { params: { userId } })
  }, [userId])

  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      <MainHeader
        title="PASSWORD SETTING"
        desc="Manage and review user's password for secure the system administration."
      />

      <main className={mainSubContainerStyle} style={{ gap: 40 }}>
        <article className="flex flex-col gap-1">
          <p className={title.two}>PASSWORD</p>
          <p className={description}>
            Need a new password? Click the link below to update
            {/* or reset it and ensure your account stays secure. */}
          </p>
        </article>

        <article className="flex flex-col gap-5">
          <article className="flex flex-col gap-4">
            <article
              className="inline-flex w-10 h-10 rounded-lg items-center justify-center"
              style={{
                background: colors.area.low,
                color: colors.neutral[60]
              }}
            >
              <Lock size={24} />
            </article>

            <p className={title.six} style={{ color: colors.text.light }}>
              The password is encrypted and can not be seen.
              <br />
              However, you can still update or generate reset password link.
            </p>
          </article>

          <article className="flex items-center gap-5">
            <Button
              styleVariants={{ type: 'outlined', size: 'small' }}
              onClick={onUpdatePassword}
            >
              Update
            </Button>
          </article>
        </article>
      </main>
    </EnterTransitionBlock>
  )
}

export default DashboardUserPasswordSetting
