import {
  getAppHome,
  getVisitorsByDevice,
  getVisitorsByLocation
} from '$services/api'
import { useRequest } from 'ahooks'

export const useAppHomeService = () => {
  const {
    run: fetchAppHome,
    loading: fetchingAppHome,
    data: appHomeData
  } = useRequest(getAppHome, { manual: true })

  const {
    run: fetchVisitorsByLocation,
    loading: fetchingVisitorsByLocation,
    data: visitorsByLocationsData
  } = useRequest(getVisitorsByLocation, {
    manual: true
  })

  const { data: appHome } = appHomeData ?? {}

  const visitors_by_location = visitorsByLocationsData?.data ?? []

  const {
    run: fetchVisitorsByDevices,
    loading: fetchingVisitorsByDevices,
    data: visitorsByDevicessData
  } = useRequest(getVisitorsByDevice, {
    manual: true
  })

  const visitors_by_devices = visitorsByDevicessData?.data ?? []

  return {
    fetchAppHome,
    fetchingAppHome,
    appHome,

    fetchVisitorsByLocation,
    fetchingVisitorsByLocation,
    visitors_by_location,
    fetchVisitorsByDevices,
    fetchingVisitorsByDevices,
    visitors_by_devices
  }
}
