import { useIsMounted } from '@genie-fintech/ui/hooks'
import { ContainerStyle, LineSeparatorStyle } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { title } from '@genie-fintech/ui/style/typography'
import { description } from '$styles/common.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import { PASSWORD_GENEREATE_TYPE } from './types'
import { Checkbox, Radio } from '@genie-fintech/ui/components'
import { Password } from '@genie-fintech/ui/components/fields'
import { generatePassword } from '$app/utils'
import { useMount } from 'ahooks'
import PasswordPolicies from '$components/PasswordPolicies'
import CopyButton from '$components/CopyButon/v2'

interface IProps {
  pwd: string
  onChangePwd: (value: string) => void

  nextSignIn: boolean
  onChangeNextSignIn: (value: boolean) => void

  error?: boolean

  showNextSignIn?: boolean
}

const UpdatePasswordBlock = ({
  pwd,
  nextSignIn,
  onChangePwd,
  onChangeNextSignIn,
  error,
  showNextSignIn = true
}: IProps) => {
  const [passwordGenerateType, setpasswordGenerateType] = useState(
    PASSWORD_GENEREATE_TYPE.AUTO
  )

  const inputRef = useRef<HTMLInputElement | null>(null)

  const isMounted = useIsMounted()

  useMount(() => {
    onChangePwd(generatePassword(10))
  })

  useEffect(() => {
    if (error) {
      inputRef.current?.focus()
    }
  }, [error])

  const onChangeView = useCallback(
    (value: string) => {
      setpasswordGenerateType(value as PASSWORD_GENEREATE_TYPE)
      const newPwd =
        value === PASSWORD_GENEREATE_TYPE.AUTO ? generatePassword(10) : ''
      onChangePwd(newPwd)
    },
    [onChangePwd]
  )

  const isCustomView = passwordGenerateType === PASSWORD_GENEREATE_TYPE.CUTSOM

  return (
    <article className={ContainerStyle} data-ready={trueOrUndefined(isMounted)}>
      <article className="grid grid-cols-1 lg:grid-cols-2 gap-16">
        <article className="flex flex-col gap-1">
          <p className={title.two}>PASSWORD</p>
          <p className={description}>
            Update or reset your password to maintain account security and
            access control.
          </p>
        </article>

        <article className="flex flex-col gap-6">
          <article className="flex flex-col gap-2">
            <p className={title.six}>Console password</p>

            <Radio.Group
              asChild
              value={passwordGenerateType}
              onValueChange={onChangeView}
              className="flex flex-col gap-2"
            >
              <main>
                <Radio.Item
                  label="Autogenerated password"
                  value={PASSWORD_GENEREATE_TYPE.AUTO}
                  styleVariants={{
                    bordered: true,
                    padded: true,
                    fullWidth: true
                  }}
                />

                <Radio.Item
                  label="Custom password"
                  value={PASSWORD_GENEREATE_TYPE.CUTSOM}
                  styleVariants={{
                    bordered: true,
                    padded: true,
                    fullWidth: true
                  }}
                />
              </main>
            </Radio.Group>
          </article>

          {isCustomView && (
            <article className={'flex flex-col gap-6'}>
              <article className="flex items-end">
                <Password
                  inputRef={inputRef}
                  label="Custom Password"
                  inputProps={{
                    value: pwd,
                    onChange: e => onChangePwd(e.currentTarget.value),
                    autoComplete: 'new-password'
                  }}
                  error={error}
                  required
                />

                <CopyButton value={pwd} color="text.light" />
              </article>

              <PasswordPolicies password={pwd} />
            </article>
          )}
        </article>
      </article>

      {showNextSignIn && (
        <>
          <article className={LineSeparatorStyle} />

          <article className="flex gap-2">
            <article className="inline-block pt-0.5">
              <Checkbox
                boxProps={{
                  checked: nextSignIn,
                  onCheckedChange: value => onChangeNextSignIn(value === true)
                }}
              />
            </article>

            <article className="flex flex-col gap-0.5">
              <p className={title.six}>
                User required to create a new password at their next sign-in for
                improved security.
              </p>
              <p className={description}>
                Users will be automatically redirected to the update password
                page.
              </p>
            </article>
          </article>
        </>
      )}
    </article>
  )
}

export default UpdatePasswordBlock
